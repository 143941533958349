.modal-feedback-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 760px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .btn__blue {
        margin-top: 24px;
        max-width: 342px;
        height: 56px;
    }

    .btn-close{
        background-image: url('./img/close.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        border: none;
        background-color: inherit;
        top: 10px;
        right: 10px;
        position: absolute;
    }

    .title {
        color: var(--text-primary-blue, #020617);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .text_grey-blue {
        color: var(--text-blue-grey, #94A3B8);
        /* Button link/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 12px;
    }
}