.set-price-and-margin-master-product {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 24px;
    max-width: unset;

    .checkbox-container  {
        color: #000;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .title_main {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 114.286% */
        letter-spacing: -0.56px;
    }

    .toolbar-top, .toolbar-medium {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        gap: 12px;
        align-items: center;

        .filters-container{
            width: 85%;
            align-items: center;

            .search-component{
                max-width: 646px;
            }
        }
    }

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        flex-wrap: wrap;
    }
}
