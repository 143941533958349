.modal-scenario-settings {
    position: absolute;
    max-width: 960px;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 8px;
    background: #FFF;

    &__header {
        width: 100%;
        padding: 16px 48px;
        border-bottom: 2px solid var(--Stroke-Primary, #EDEFFB);
        background: #F8F9FE;

        .title_main {
            color: #020617;
            font-family: Rubik;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        height: calc(100% - 66px);
        padding: 32px 48px;
        overflow: auto;

        .text {
            color: #475569;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .content-group {
            display: flex;
            gap: 24px;
            width: 100%;
            height: 100%;

            .right-content,
            .left-content {
                display: flex;
                flex-direction: column;
                gap: 24px;
                width: calc(50% - 12px);

                &__item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 12px;

                    .selector_wrapper {
                        max-width: 100%;
                    }

                    .title {
                        color: #020617;
                        font-family: Rubik;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                    }
                }
            }

            .right-content {
                min-width: 400px;

                &__item {
                    height: 100%;

                    .textarea-dynamic-placeholder {
                        height: calc(100% - 24px) ;

                        &__content {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}