.options-executor {
    display: flex;
    align-items: center;
    width: auto;
    gap: 16px;
    padding: 4px 16px;

    img {
        width: 20px;
        height: 20px;
    }

    .text-box {
        display: flex;
        flex-direction: column;

        .text {
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 180px;
        }

        .text_grey {
            font-size: 10px;
            color: grey;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 180px;
        }
    }

    &:hover {
        background-color: #E6F7FF;
        transition: background-color 0.5s;
    }
}

.executor-placeholder {
    border-radius: 50%;
    background-color: white;
    color: #fff;
    border: 1px solid #fff;
    text-align: center;
    font-family: NunitoSans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    min-width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    userSelect: none;
    cursor: pointer;
}