.modal-add-employee {
    max-width: 1000px;
    min-width: 360px;
    min-height: 360px;
    height: auto;
    align-items: center;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    margin: auto;
    position: relative;
    z-index: 10000;
    position: relative;

    &__header {
        display: flex;
        width: 100%;
        gap: 24px;

    }

    &__content {
        display: flex;
        gap: 24px;
    }

    &__btn-close {
        border: none;
        background-color: inherit;
        cursor: pointer;
        position: absolute;
        top: 24px;
        right: 24px;
    }

    &__left-content,
    &__right-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 50%;
    }

    &__right-content {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 12px;
    }

    &__box-space {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: 1px solid #EDEFED;
        overflow: auto;
        max-height: 342px;
    }

    &__title {
        width: 100%;
        color: #020617;
        font-family: NunitoSans;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        width: 50%;
    }

    &__title-min {
        color: #0C2037;
        font-family: "Golos Text";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    &__text {
        color: #5C7189;
        font-family: "Golos Text";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    &__content-btn {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &__no-data {
        width: 100%;
        background-color: inherit;
        border: none;
        cursor: pointer;
        color: #020617;
        text-overflow: ellipsis;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        padding: 16px 20px;
    }

    &__drop-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
        background-color: inherit;
        border: none;
        cursor: pointer;
        color: #020617;
        text-overflow: ellipsis;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        padding: 16px 20px;
        text-align: left;
        border-bottom: 1px solid #EDEFED;


        &:hover {
            transition: 0.5s;
            background: #FAFBFA;
        }
    }

    .selector_wrapper {
        max-width: 100%;
        min-height: 56px;
        margin-top: 24px;

        .css-13cymwt-control,
        .css-t3ipsp-control {
            min-height: 56px;
        }
    }

    .checkbox-box {
        margin-top: 24px;
        display: flex;
        gap: 12px;
        align-items: flex-start;
    }

    .btn-basic {
        margin-top: 24px;
        align-self: flex-end;
    }

}