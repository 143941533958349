.messages-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__item,
    &__item_active {
        display: flex;
        width: 100%;
        min-height: 96px;
        padding: 18px 20px;
        align-items: center;
        flex-shrink: 0;
        gap: 10px;
        position: relative;
        border-bottom: 1px solid #D1D5DB;
        cursor: pointer;

        .new-message {
            position: absolute;
            top: calc(50% - 4px);
            width: 8px;
            height: 8px;
            border-radius: 1px;
            background: #EF4444;
            right: 20px;
        }

        .read-message {
            position: absolute;
            top: calc(50% - 9px);
            right: 20px;
        }

        &_active,
        &:hover {
            transition: 0.5s;
            background: #F0FDF4;
        }

        .img-box {
            min-width: 40px;
            max-width: 40px;
            border-radius: 50%;
            overflow: hidden;
            height: 40px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .message-box {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: calc(100% - 50px - 32px);

            .title {
                color: #000;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: -webkit-box;
                -webkit-line-clamp: 1; // количество строк
                -webkit-box-orient: vertical;
                overflow: hidden;
                hyphens: auto;
                word-break: break-word;
            }

            .text {
                display: -webkit-box;
                -webkit-line-clamp: 1; // количество строк
                -webkit-box-orient: vertical;
                overflow: hidden;
                hyphens: auto;
                color: #000;
                text-overflow: ellipsis;
                font-family: NunitoSans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            .title-and-date {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: start;
                justify-content: space-between;
                gap: 10px;
                overflow: hidden;

                .info-message {
                    display: flex;

                    .title {
                        //white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #6B7280;
                        font-family: NunitoSans;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 14px;
                        padding-right: 6px;
                        border-right: 1px solid #6B7280;
                    }

                    .text_grey {
                        color: #6B7280;
                        font-family: NunitoSans;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 14px;
                        padding-left: 6px;
                        white-space: nowrap;
                        min-width: 90px;
                    }
                }


                .text_grey {
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }
}