.content-transfer,
.update-prices {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;

    .title-main-content {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 12px;

        .title {
            color: #020617;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.modal-successful {
    .content-description {
        display: flex;
        gap: 8px;
        align-items: center;
    }
}

.content-transfer {
    position: relative;
    width: 100%;
    height: 100%;

    .toolbar-top {
        display: flex;
        width: 100%;
        gap: 12px;
        align-items: center;
        flex-wrap: wrap;
    }

    .toolbar-medium {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;

        .btn-content {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
    }
}