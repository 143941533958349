.calculation-delivery {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    gap: 24px;

    &__title-main {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    &__toolbar-top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        gap: 12px;
        align-items: center;

        &_right {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }

    &__header-table {
        display: flex;
        width: 100%;
        gap: 12px;
        align-items: center;
        flex-wrap: wrap;
    }

    &__label {
        display: flex;
        gap: 12px;
        align-items: center;
        flex-wrap: wrap;

        span {
            color: #020617;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }

    &__input {
        width: 110px;
        height: 32px;
        padding: 6px 12px;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #51CA64;
        color: #020617;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:focus {
            outline: none;
        }
    }

    &__info-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 590px;
        gap: 8px;
    }

    &__title-tooltip {
        color: #000;
        font-family: NunitoSans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__text-tooltip {
        color: #000;
        font-family: NunitoSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}