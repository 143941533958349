.analytics-search-query {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    overflow: hidden;

    .charts-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }

    .summary-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width:  100%;
    }

    .content-1320 {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;

        .checkbox-container {
            display: flex;
            gap: 8px;
            align-items: center;

            .text_label,
            .text_label_disabled {
                color: #020617;
                font-family: Rubik;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;

                &_disabled {
                    color: #D3DEE7;
                }
            }
        }

        .card-content {
            display: flex;
            gap: 16px;
            width: 100%;
            height: 100%;
            flex-wrap: wrap;
    
            &__item {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 30%;
                min-width: 283px;
                max-width: 406px;
            }
    
            &__header {
                display: flex;
                align-items: center;
                gap: 12px;
                width: 100%;
                background-color: #fff;
                min-height: 34px;
                max-height: 34px;
                padding: 8px;
                border-radius: 8px;
    
                .text {
                    color: #636D65;
                    font-family: NunitoSans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
    
            &__content {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                background-color: #fff;
                min-height: calc(78px * 3);
                border-radius: 8px;
    
                .сard-statistics {
                    border: none;
                }
            }
        }
    }

    .card-main-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 16px;

        .card-group {
            display: flex;
            flex-direction: column;
            width: fit-content;
            gap: 8px;
            // max-width: calc(203px*3 + 8px*2);

            .title_card {
                color: #64748B;
                font-family: Rubik;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            &__content {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                gap: 8px;
            }
        }
    }

    .title_main {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 12px;
        letter-spacing: -0.56px;
    }

    .toolbar-top {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 24px;

        .text_tooltip {
            color: #11195D;
            font-family: Rubik;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;

            span {
                @extend .text_tooltip;
                color: #555C9E;
            }
        }
    }

    .charts-group {
        display: flex;
        width: 100%;
        gap: 2%;

        .line_chart {
            width: 60%;
        }

        .cup-component {
            width: 38%;
        }
    }

    .range-group-btn-and-range-box {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-left: auto;
        flex-wrap: wrap;

        .selector_wrapper {
            max-width: 124px;
            min-width: 124px;
        }

        .drop-box {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .chart-and-metrics {
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }

    .search-component {

        .input-box-ver2 {
            max-width: 100%;
        }

        .search-input-ver2 {
            max-width: 100%;
        }
    }

    .product-analysis {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        flex-direction: column;
        border-radius: var(--base-radius, 8px);
        border: 1px solid var(--stroke-primary-primary, #E0E7F2);

        &__header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;

            .btn {
                height: 41px;
                white-space: nowrap;
                padding: var(--space-x-3, 12px) var(--space-x-2, 8px);
                border-radius: 8px;
                border: 1px solid #E0E7F2;
                background: #FFF;
            }
        }

        .group-btn {
            display: flex;
            gap: 8px;
            align-items: center;

            button {
                display: flex;
                white-space: nowrap;
                padding: var(--space-x-2, 8px);
                border-radius: var(--base-radius, 8px);
                border: 1px solid var(--stroke-primary-primary, #E0E7F2);
                color: var(--main-menu-secondary-text, #475569);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                background-color: white;
            }
        }
    }

    .metrics-items {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        flex-wrap: wrap;
        width: 100%;
    }

    .review-table {
        display: flex;
        padding: 24px;
        flex-direction: column;
        gap: var(--12, 12px);
        border-radius: var(--base-radius, 8px);
        border: 1px solid var(--stroke-primary-primary, #E0E7F2);
        background: var(--bg-seondary, #F5F7F8);

        .title {
            color: var(--main-menu-secondary-text, #475569);
            /* Desktop 2.0/Heading/Heading 6 */
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }
    }

    .position-in-category,
    .similar-products {
        display: flex;
        padding: var(--space-x-6, 24px);
        flex-direction: column;
        gap: var(--12, 12px);
        border-radius: var(--base-radius, 8px);
        border: 1px solid var(--stroke-primary-primary, #E0E7F2);
        background: var(--bg-seondary, #F5F7F8);
    }

    .relevant-words {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;

        &__content {
            display: flex;
            gap: 20px;
            align-items: end;

            .table-box-component {
                max-width: calc(50% - 10px);
            }

            .word-cloud-container {
                min-width: 600px;
                width: calc(50% - 10px);
            }

            .table-box-component__content {
                min-height: 350px;
                max-height: 350px;
            }
        }
    }

    .table-content {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;

        .title {
            color: #000;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            margin-bottom: 12px;
        }
    }
}