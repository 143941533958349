.modal-unanswered-Feedbackmodal {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    gap: 20px;
    max-width: fit-content;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        span {
            font-weight: 800;
            line-height: 28px;
        }
    }

    .table-without-data {
        min-height: 350px;
    }
}