@import '../../common/styles/mixins.scss';
@import '../../common/styles/svg_img.scss';

// Основной блок

.main-menu {
    z-index: 8 !important;
}

.option-space {
    display: flex;
    align-items: center;
    gap: 8px;
}

.main-sidebar {
    z-index: 9;
}

.main-sidebar {
    display: block;
    width: auto;
    padding: 0px 20px;
    background-color: #fff;
    gap: 16px;

    &__btn-info {
        border: none;
        background-color: inherit;
        cursor: pointer;
    }

    &__space-content {
        display: flex;
        gap: 6px;
        align-items: center;
    }

    .logo-and-search {
        display: flex;
        gap: 16px;
        align-items: center;
        width: auto;
        min-height: 60px;
        margin-right: 24px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .input-field-wrapper {
            min-width: 200px;
            width: 100%;
            max-width: 512px;
        }
    }

    &__nav {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        column-gap: 24px;
        flex-wrap: wrap;
    }

    &__group-btn {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    &__left-box {
        display: flex;
        gap: 40px;
        width: 100%;
        align-items: center;
    }

    &__right-box {
        display: flex;
        gap: 20px;
        align-items: center;
        min-height: 60px;
        margin-left: auto;

        .tooltip-container {
            width: 36px;
            height: 36px;
            position: relative;

            .tooltip_light-blue {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                z-index: 11;
                top: 36px;
                left: 15px;
                padding: 6px;
                border-radius: 2px;
                background: #E7F1FF;
                color: #005DB2;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }

            &:hover {
                .tooltip_light-blue {
                    opacity: 1;
                    visibility: visible;
                    transition: 0.5s;
                }
            }

            .notification-count {
                position: absolute;
                top: -4px;
                left: 22px;
                border-radius: 8px;
                background-color: #48A8FE;
                display: flex;
                min-width: 16px;
                width: auto;
                padding-left: 4px;
                padding-right: 4px;
                height: 16px;
                justify-content: center;
                align-items: center;
                z-index: 1;
                color: white;
                font-size: 12px;
            }
        }
    }
}

.search-container {
    position: relative;
    width: 40px;
    height: 40px;


    .magnifier {
        position: absolute;
        pointer-events: none;
        left: 13px;
        top: 12px;
        width: 16px;
        height: 16px;
        z-index: 1;
    }

    &:hover {
        width: 256px;
        cursor: pointer;

        // .magnifier {
        //     left: 10px;
        //     top: 10px;
        // }

        .search {
            color: #020617;
            outline: none;
            border: 1px solid #F0F0F0;
        }
    }

    .search {
        width: 40px;
        height: 40px;
        border-radius: 22px;
        padding: 10px;
        transition-duration: 1000ms;
        cursor: pointer;
        background-repeat: no-repeat;
        font-size: 12px;
        color: transparent;
        border: 1px solid #F0F0F0;
        overflow: hidden;
        background-color: inherit;
        padding-left: 30px;

        &:hover {
            width: 256px;
            cursor: pointer;
            padding-left: 30px;
            color: #020617;
            outline: none;
            border: 1px solid #F0F0F0;
            background-position: 10px 50%;
        }

        &::placeholder {
            color: #D9D9D9;
            font-size: 14px;
        }

        &:focus {
            width: 256px;
            cursor: pointer;
            padding-left: 30px;
            color: #020617;
            outline: none;
            border: 1px solid #F0F0F0;
            background-position: 10px 50%;
        }
    }
}

// Кнопки

.notifications__btn,
.message__btn,
.upload__btn,
.task__btn,
.setting__btn,
.chat__btn {
    display: inline-block;
    position: relative;
    width: 36px;
    height: 36px;
    border: none;
    background-size: 28px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
    border: 1px solid #EDEFED;
    cursor: pointer;

    &:hover {
        transition: background-color 0.3s;
    }
}

.notifications__btn {
    background-image: url('./img/question.svg');
    position: relative;

    // &::after {
    //     content: '';
    //     width: 8px;
    //     height: 8px;
    //     background-color: #FF1E39;
    //     border-radius: 50%;
    //     position: absolute;
    //     top: -1px;
    //     right: -1px;
    // }
}

.upload__btn {
    background-image: url('./img/load.svg');
}

.message__btn {
    background-image: url('./img/bell.svg');
}

.setting__btn {
    background-image: url($setting2);
}

// Блок профиля

.profile-box {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 10px;
    position: relative;

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        cursor: pointer;
        border: 1px solid #E1E6EA;
        color: white;
        background: #F79E6C;
        //  background-image: url('./img/user.svg');
        //  background-position: center;
        // background-repeat: no-repeat;
        color: #FFF;
        text-align: center;
        font-family: NunitoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        border: none;
    }

    &__user-name {
        color: #020617;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
    }

    & .profile_dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        background-color: rgb(211, 211, 211);
        width: 100%;
        padding: .5em 1em;
        margin-top: .75em;
        color: #020617;
        z-index: 5;
        transition: .3s;
        border: 1px solid grey;
        border-top: none;
        border-bottom-right-radius: 1em;
        border-bottom-left-radius: 1em;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-100%);
        display: flex;
        flex-direction: column;
        row-gap: .35em;

        &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);

            &>span {
                display: inline-block;
                width: 100%;
                user-select: none;
                cursor: pointer;

                &:hover {
                    color: rgb(94, 94, 94);
                }
            }
        }
    }
}

.training-nav {
    display: flex;
    gap: 40px;
    align-items: center;

    &__item {
        font-size: 14px;
    }

    &__item_active {
        font-size: 20px;
    }
}

.notifications-list__content,
.balance-box-num {
    display: flex;
    gap: 4px;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    border-left: 1px solid #D8DDF5;
    border-right: 1px solid #D8DDF5;
    white-space: nowrap;
    text-align: center;
    color: #0B110B;
    text-align: center;
    font-family: NunitoSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    height: 100%;

    span {
        color: #A1D9A3;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }
}


//////////////////////////////// ver-2
/// 
.main-sidebar {
    // display: flex;
    // justify-content: space-between;
    // gap: 12px;
    // width: auto;
    // padding: 0.5em 30px;
    // background-color: white;
    // flex-wrap: wrap;
    // min-height: 60px;
    // height: auto;
    // // border-bottom: 1px solid #D9D9D9;
    // background-color: #FAFBFE;

    .select-group {
        display: flex;
        gap: 12px;
    }

    .selector_wrapper {
        min-width: 210px;
        max-width: 210px;
    }

    .select-period-group {
        display: flex;
        gap: 12px;
        align-items: center;
    }
}