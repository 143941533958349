.comment {
    display: flex;
    gap: 8px;
    background-color: #FFF;
    border-radius: 6px;
    padding: 12px 20px;
    border-radius: 6px;

    &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 12px;
    }

    &__executor-content {
        min-width: 32px;
        width: 32px;
        height: 32px;
    }

    &__input-field {
        width: 100%;

        input {
            width: 100%;
            min-height: 40px;
            border-radius: 8px;
            border: 1px solid #E1E6EA;
            background: #FFF;
            padding-left: 12px;
            padding-right: 12px;
            color: #8F99A3;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            font-family: Inter;

            &::placeholder {
                font-family: Inter;
                color: #8F99A3;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            &:focus {
                border: 1px solid #E1E6EA;
                outline: none;
            }
        }

        input[disabled] {
            border: 1px solid #F8F9F8;
            background: #F8F9F8;
            cursor: not-allowed;
        }
    }
}