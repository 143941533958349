.list-promotion-ver2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 24px;

    .title_main {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 12px;
    }

    .content-btn {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .filter-and-btn {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        gap: 12px;
        align-items: flex-start;

        .filter-group {
            display: flex;
            justify-content: start;
            gap: 12px;
            align-items: center;
            flex-wrap: wrap;
        }

        .btn__blue {
            min-width: 229px;
            width: 100%;
            max-width: 229px;
            width: 229px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    .toolbar-top {
        display: flex;
        width: 100%;
        gap: 12px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .btn__blue {
        min-height: 40px;
        max-width: 229px;
        min-width: 229px;
        padding: 0px 8px;
    }
}