.editor-component,
.editor-component_disabled {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_disabled {
        cursor: default;
    }

    .redactor-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 4px;

        .text-content {
            width: 100%;
            height: auto;
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-radius: 8px;

            .title {
                color: #475569;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            .text {
                color: #53616E;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                width: 100%;
                padding: 4px;
                background: #fff;
                border-radius: 10px;
                border: 1px solid #E7E7E7;
                padding: 6px;
                word-break: break-word;

                img {
                    max-width: 40%;
                }

                &:hover {
                    transition: 0.3s;
                    background: #f6f8fd;
                }
            }

            .text a{
                color: #1890FF;
            }
        }

        .title_redactor {
            color: #8F99A3;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .ql-editor.ql-blank::before {
            font-style: normal;
            color: #53616E;
            font-size: 13px;
            font-weight: 400;
            font-family: Inter;
        }

        .ql-container {
            max-height: 200px;
            overflow: auto;
        }

        .quill {
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: 180px;

            // &:hover {
            //     .ql-toolbar,
            //     .ql-container {
            //         border: 1px solid #64748B;
            //     }
            // }

            &:focus {

                .ql-toolbar,
                .ql-container {
                    border: 1px solid rgb(24, 144, 255)
                }
            }

            // Верхняя панель
            .ql-toolbar {
                display: block;
                background: #ffffff;
                border-top-left-radius: 0.5em;
                border-top-right-radius: 0.5em;
                border: 1px solid #E7E6E9;
                border-radius: 8px 8px 0 0;
            }

            // Контейнер содержимого текста
            .ql-container {
                height: calc(100%);
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                border: 1px solid #E7E6E9;
                background: #ffffff;
            }
        }

    }

    .btn-group {
        display: flex;
        gap: 20px;

        .link-blue {
            color: var(--button-primary-default, #80C2FF);
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            &:hover {
                transition: 0.5s;
                color: #1890FF;
            }
        }
    }
}