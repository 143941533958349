.set-remaining-goods {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    max-width: calc(1440px - 120px);
    margin-left: auto;
    margin-right: auto;
    gap: 24px;

    .title_main {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 114.286% */
        letter-spacing: -0.56px;
    }

    .toolbar-top, .toolbar-medium {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        gap: 12px;
    }

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        flex-wrap: wrap;
    }
}