.modal-knowledge-base {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    //max-width: 650px;
    width: fit-content;
    min-width: 360px;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    max-height: calc(100vh);

    .article-content {
        padding: 0;
    }

    .date-box {
        flex-direction: row;
    }

    .input-field-wrapper {
        max-width: 100%;
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        width: 245px;
        min-width: 245px;
        background-color: #F0F2F4;

        .sidebar-content {
            display: flex;
            flex-direction: column;
            gap: 24px;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 16px;
        }

        .title {
            display: flex;
            align-items: center;
            gap: 6px;
            color: #2B3340;
            text-align: center;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            padding: 16px;
        }

        .card-info-knowledge {
            display: flex;
            width: 100%;
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;

            .title_section {
                color: #5A6577;
                text-align: center;
                font-family: NunitoSans;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                cursor: pointer;
            }

            &__content {
                display: flex;
                flex-direction: column;
                gap: 12px;
                width: fit-content;

                .label-and-icon {
                    display: flex;
                    width: 100%;
                    gap: 12px;
                    align-items: center;
                    justify-content: space-between;
                }

                .text,
                .text_active {
                    color: #2B3340;
                    font-family: NunitoSans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    cursor: pointer;

                    &_active {
                        color: #4285F4;
                    }

                    &:hover {
                        transition: 0.3s;
                        color: #4285F4;
                    }
                }

                .drop-conent,
                .drop-conent_active {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                    cursor: pointer;
                    max-height: 0px;
                    overflow: hidden;
                    display: none;

                    .text {
                        font-weight: 400;
                    }

                    &_active {
                        display: flex;
                        max-height: 200px;
                    }
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        min-width: (342px*2 + 32px + 33px);
        max-width: (342px*2 + 32px + 33px);
        padding: 16px;
        gap: 20px;
        overflow: auto;

        .title {
            color: #2B2B46;
            font-family: "Golos Text";
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }

        .article-main-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 20px;
            justify-content: space-between;
            max-height: calc(100vh - 40px - 60px - 32px) ;
            height: 100%;

            &__content {
                display: flex;
                width: 100%;
                gap: 20px;
                flex-wrap: wrap;
                overflow: auto;
                height: 100%;
            }
        }
    }

    .breadcrumbs-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &__item {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            color: #2B2B46;
            font-family: NunitoSans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            white-space: nowrap;
            cursor: pointer;

            &::after {
                content: '/';
                display: inline-block;
                margin-left: 12px;
                margin-right: 12px;
            }

            &:nth-last-of-type(1) {
                &::after {
                    content: '';
                    display: none;
                }
            }
        }
    }

    .top-panel {
        display: flex;
        gap: 20px;
        align-items: center;

        .input-field-wrapper {
            max-width: 100%;
        }

        .tooltip-container {
            position: relative;

            .tooltip_light-blue {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                z-index: 11;
                top: 46px;
                right: 0px;
                padding: 6px;
                border-radius: 2px;
                border-radius: 2px;
                border: 1px solid var(--slate-200, #C5CCD3);
                background: var(--slate-100, #F1F5F9);
                color: #005DB2;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                white-space: nowrap;
            }

            &:hover {
                .tooltip_light-blue {
                    opacity: 1;
                    visibility: visible;
                    transition: 0.5s;
                }
            }

            .notification-count {
                position: absolute;
                top: -4px;
                left: 22px;
                border-radius: 8px;
                background-color: #48A8FE;
                display: flex;
                min-width: 16px;
                width: auto;
                padding-left: 4px;
                padding-right: 4px;
                height: 16px;
                justify-content: center;
                align-items: center;
                z-index: 1;
                color: white;
                font-size: 12px;
            }
        }

        .blue-light-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: var(--4, 4px);
            border: 1px solid var(--text-teritary, #4CA9FF);
            background: var(--row-hover, #EDF8FE);
            max-width: 40px;
            min-width: 40px;
            max-height: 40px;
            min-height: 40px;
            padding: 0;
        }
    }

    .content-article {
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .rate-article {
        display: flex;
        gap: 16px;
        padding: 24px;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        border-radius: 8px;
        background: #E7F1FF;

        .text {
            color: #004280;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px
        }

        .rate-content {
            display: flex;
            gap: 10px;
        }

        .positive-rating,
        .positive-rating_active,
        .negative-rating,
        .negative-rating_active {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 4px;
        }

        .positive-rating {

            &:hover,
            &_active {
                path {
                    fill: #1890FF;
                }
            }
        }

        .negative-rating {

            &:hover,
            &_active {
                path {
                    fill: #1890FF;
                }
            }
        }
    }
}