.from-to-component {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 188px;
    max-width: 188px;
    width: 100%;
    position: relative;

    &__header {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        align-items: center;

        .title_min {
            font-weight: 400;
            color: #636363;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .btn__question {
            min-width: 16px;
            width: 16px;
            height: 16px;
            background-image: url('./img/question.svg');
            border: none;
            background-color: inherit;
        }

        .btn__close {
            width: 16px;
            height: 16px;
            background-image: url('./img/close.svg');
            border: none;
            background-color: inherit;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        min-height: 40px;
        max-height: 40px;
        border-radius: 8px;
        border-radius: 8px;
        border: 1px solid #E0E7F2;
        gap: 12px;
        padding: 8px;

        input {
            width: 100%;
            max-width: calc(50% - 6px);
            border: 1px solid #BDBCDB;
            padding-left: 4px;
            padding-right: 4px;
            border-radius: 4px;
            font-size: 12px;
            color: #020617;

            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }

            &:focus {
                outline: none;
                border: 1px solid #1890FF;
                -webkit-box-shadow: 0px 0px 4px 0px rgba(24, 144, 255, 0.4);
                -moz-box-shadow: 0px 0px 4px 0px rgba(24, 144, 255, 0.4);
                box-shadow: 0px 0px 4px 0px rgba(24, 144, 255, 0.4);
            }

            &::placeholder {
                color: #AAAAAA;
                font-size: 12px;
            }
        }
    }

    .drop-content,
    .drop-content_hide {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: calc(100% - 8px);
        left: 0;
        width: 100%;
        height: auto;
        background-color: white;
        border-radius: 8px;
        padding: 10px;
        align-items: center;
        gap: 8px;
        overflow: hidden;
        transition: height 1s;
        box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.04);
        z-index: 1;

        .btn__blue {
            border-radius: 8px;
            font-size: 13px;
        }

        .btn__white-border {
            border: none;
            background-color: white;
            color: #888888;
            font-size: 13px;
            height: 40px;
            height: 24px;
            border-bottom: 1px solid #E0E7F2;
            border-radius: 8px;
            width: 77px;
        }

        .from-to-component-group {
            display: flex;
        }
    }

    .drop-content_hide {
        padding: 0;
        transition: height 1s;
        height: 0;
    }
}