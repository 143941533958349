.deliveries {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    .content-1440 {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
    }

    .title_main {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; 
        margin-bottom: 12px;
        letter-spacing: -0.56px;
    }

    .toolbar-medium {
        display: flex;
        width: 100%;
        align-items: end;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 30px;
    }

    .card-group {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .btn-group {
        display: flex;
        gap: 20px;

        .group-left, .group-right {
            display: flex;
            gap: 8px;    
        }

        .btn-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            background: #DDEEDD;
            border-radius: 4px;
            border: none;

            &:hover {
                transition: 0.5s;
                border: 1px solid #15803D;
            }
        }
    }
}