.modal-list-executor {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    max-width: 440px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-radius: 8px 0 0 8px;
    height: 100%;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;

    .tabs {
        padding-left: 24px;
        padding-right: 24px;
    }

    &__header {
        padding: 20px 24px;
        width: 100%;
        border-bottom: 1px solid #EDEFED;
        background: #FAFBFA;

        .title {
            font-family: NunitoSans;
            color: #0B110B;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }

    &__toolbar {
        display: flex;
        gap: 12px;
        padding: 12px 24px;
        width: 100%;
        border-bottom: 1px solid #EDEFED;

        .btn-sort,
        .btn-sort_active {
            display: flex;
            width: 40px;
            min-width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #ACB0AD;
            background: #FFF;

            &:hover,
            &_active {
                border: 1px solid #636D65;

                svg {
                    path {
                        fill: #636D65;

                        &:nth-child(3) {
                            stroke: #636D65;
                        }

                        &:nth-child(4) {
                            stroke: #636D65;
                        }
                    }
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        padding: 8px 20px;
        max-height: 483px;
        min-height: 483px;
        height: 100%;
    }

    .input-field-wrapper {
        max-width: 100%;
    }

    .executor-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100% - 130px - 72px - 41px);
        position: relative;

        &__info {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding: 4px 24px;
            margin-top: 72px;

            .text {
                color: #636D65;
                font-family: NunitoSans;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                span {
                    color: #0B110B;
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .text_bluee {
                color: rgba(0, 9, 232, 0.61);
                font-family: NunitoSans;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }

        .no-executor {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            max-width: 60%;
            height: 100%;

            .text_grey {
                text-align: center;
                color: #94A3B8;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }

        .executors-list-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;

            .text_grey {
                display: flex;
                gap: 12px;
                align-items: center;

                .blue-sum {
                    display: flex;
                    padding: 0px 4px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: #B2DAFF;
                }
            }
        }

        .list-executor {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: calc(100% - 30px);
            overflow: auto;

            .executor-placeholder {
                min-width: 48px;
                width: 48px;
                height: 48px;
                min-height: 48px;
                margin-left: 4px;
            }

            .executor-item,
            .executor-item_active {
                display: flex;
                align-items: center;
                gap: 14px;
                padding: 12px;
                padding-left: 24px;
                cursor: pointer;
                width: 100%;
                top: 0;

                &:hover,
                &_active {
                    transition: 0.5s;
                    border-radius: 4px;
                    background-color: #F4F6F4;
                }

                .info-content {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 100px);
                }

                .text {
                    width: 100%;
                    overflow: hidden;
                    color: #020617;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px;
                }

                .text_grey {
                    display: inline-block;
                    width: 100%;
                    overflow: hidden;
                    color: #020617;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: var(--Text-Secondary, #636D65);
                    font-family: Rubik;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 14px;
                }
            }
        }
    }

    .btn-box {
        display: flex;
        width: 100%;
        justify-content: end;
        padding: 16px 24px;
    }

}