body {
    .tooltip-green, .tooltip-green_active {
        display: flex;
        align-items: start;
        gap: 12px;
        padding: 8px 12px;
        border-radius: 8px;
        width: 100%;
        background-color: #EBF6EB;
        color: #15803D;
        font-size: 14px;
        border: 1px solid var(--success-secondary-text, #15803D);
        opacity: 0;
        visibility: hidden;
        transition: 1.5s;
        animation: hidden 1.5s forwards;

        @keyframes hidden {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        &_active {
            opacity: 1;
            visibility: visible;
            transition: 2s;
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .title {
                color: var(--text-accent-blue, #15803D);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            .text_grey-green {
                color: #92CEA8;
                font-size: 14px;
            }
        }

        .close {
            margin-left: auto;
            cursor: pointer;
        }
    }
}