.modal-change-of-legal-entities {
    max-width: 760px;
    min-width: 360px;
    width: 100%;
    min-height: 360px;
    height: auto;
    position: relative;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5000;

    .title {
        color: var(--text-primary-blue, #020617);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .text_grey-blue {
        margin-top: 12px;
        color: var(--text-blue-grey, #94A3B8);
        /* Button link/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 114.286% */
    }

    .close {
        position: absolute;
        top: 21px;
        right: 21px;
        width: 9px;
        height: 9px;
    }

    img {
        margin: 24px;
    }

    .selector_wrapper {
        max-width: 100%;
        min-height: 56px;
        margin-top: 24px;

        .css-13cymwt-control,
        .css-t3ipsp-control {
            min-height: 56px;
        }
    }

    .checkbox-box {
        display: flex;
        gap: 12px;
        align-items: flex-start;
        margin-top: 24px;
    }

    .text_grey {
        font-size: 14px;
        color: #888888;
    }

    .group-btn {
        display: flex;
        width: 100%;
        gap: 12px;
        margin-top: 24px;
    }

    .input-box {
        margin-top: 24px;
    }

}

.option-item {
    &:hover {
        background-color: hsl(0, 0%, 90%);
        cursor: pointer;
    }

    &:active {
        background-color: hsl(0, 0%, 90%);
        cursor: pointer;
    }
}