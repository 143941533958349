.modal-new-task-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 460px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);

    .input-box {
        margin-top: 0;
    }
}