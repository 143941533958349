@import 'src/common/styles/svg_img.scss';

.notification {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;

    .notification-box {
        background-color: white;
        padding: 16px 24px 24px;
        border-radius: 16px;
    }

    &__info-box {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        gap: 16px;

        .contacts-box {
            display: flex;
            align-items: center;
            gap: 64px;

            .text {
                margin-top: 0;
            }

            .link__blue {
                font-size: 14px;
            }

            .contact-and-edit {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                max-width: 240px;
                width: 100%;
            }

            .text-and-icon {
                min-width: 112px;
                font-size: 14px;

                &::before {
                    content: "";
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 16px;
                    height: 16px;
                    vertical-align: middle;
                    margin-right: 16px;
                }

                &_messages {
                    &::before {
                        background-image: url($message_icon);
                    }
                }

                &_whatsapp {
                    &::before {
                        background-image: url($whatsapp_icon);
                    }
                }

                &_tel {
                    &::before {
                        background-image: url($tel);
                    }
                }

                &_tg {
                    &::before {
                        background-image: url($tg_icon);
                    }
                }
            }
        }
    }

    .title {
        font-size: 16px;
        font-weight: 500;
        padding: 0;
    }

    .text {
        font-size: 14px;
        margin-top: 16px;
    }

    label {
        display: flex;
        justify-content: center;
        cursor: pointer;
    }
}