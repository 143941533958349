@import 'src/common/styles/svg_img.scss';

.modal-add-project {
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    min-width: 360px;
    background-color: white;
    border-radius: 16px;
    max-height: calc(100vh - 120px);
    gap: 16px;
    justify-content: space-between;
    max-width: 960px;
    width: 100%;

    &__header {
        padding: 4px;
        padding-left: 20px;
        width: 100%;
        border-radius: 4px 4px 12px 12px;
        border: 4px solid #FFF;
        background: var(--info-bg, #E7F1FF);
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #404E8C;

        .title {
            color: #404E8C;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
        }

        .right-content {
            display: flex;
            align-items: center;
            margin-right: 10px;


            .tooltip-box {
                position: relative;

                .tooltip_black {
                    opacity: 0;
                    visibility: hidden;
                    background-color: #4A4A4A;
                    border-radius: 2px;
                    overflow: hidden;
                    color: white;
                    position: absolute;
                    font-size: 12px;
                    top: calc(100% + 4px);
                    right: 0;
                    z-index: 100;
                    width: max-content;
                    height: auto;
                    padding: 2px 4px;
                    border: 1px solid #E0E7F2;
                }

                &:hover {
                    .tooltip_black {
                        opacity: 1;
                        visibility: visible;
                        transition: 0.5s;
                    }
                }
            }

            .btn-link {
                display: flex;
                width: 24px;
                height: 24px;
                border-radius: 4px;
                border: 1px solid #E0E7F2;
                background: #F5F7F8;
                background-image: url('./img/link_icon.svg');
                background-size: 14px;
                background-repeat: no-repeat;
                background-position: center;
            }

            .text_date {
                color: #8F99A3;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-right: 16px;
            }

        }


    }

    &__content {
        display: flex;
        gap: 24px;
        padding: 24px;
        position: relative;
    }

    .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 140px;
        width: 260px;
        border-radius: 4px;
        border: 1px solid #E0E7F2;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 0.5s;

        &_blackout {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: black;
            opacity: 0;
            transition: 0.5s;

            span {
                position: relative;
                z-index: 1;
                opacity: 1;
                color: #EBF5FF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }
        }

        &:hover {
            .img-box_blackout {
                opacity: 0.6;
            }
        }

        .img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }

    .context-more {
        z-index: 5;

        .btn-more {
            width: 24px;
            min-width: 24px;
            height: 24px;
            background-image: url('./img/more-vertical.svg');
            border: none;
            background-color: inherit;
        }

        .drop-menu,
        .drop-menu_active {
            position: absolute;
            top: calc(100% + 10px);
            right: 0px;
            width: 124px;
            height: 32px;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            border: 1px solid #E0E7F2;
            background: #FFF;
            box-shadow: 6px 8px 8px 0px rgba(30, 41, 59, 0.09);
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;

            &_active {
                opacity: 1;
                visibility: visible;
                transition: 0.3s;
            }

            &__btn {
                padding-left: 8px;
                padding-right: 8px;
                width: 100%;
                height: 100%;
                background-color: inherit;
                border: none;
                text-align: left;
                color: var(--text-primary-black, #1E293B);
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &:hover {
                    transition: 0.3s;
                    background-color: #F7FAFD;
                }
            }
        }
    }

    //option status
    .option-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 8px;

        .status_red {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #FECACA;
            border: 1px solid #EF4444;
        }

        .status_yellow {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #FFEDD5;
            border: 1px solid #FDBA74;
        }

        .status_green {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #BBF7D0;
            border: 1px solid #15803D;
        }
    }

    .main-info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 60%;

        .input-box {
            margin-top: 0;
        }
    }

    .additional-information {
        display: flex;
        flex-direction: column;
        gap: 12px;
        min-width: 40%;
        max-width: 40%;
        overflow-y: auto;

        .range-box-single .date-picker input {
            padding-bottom: 0;
            height: 56px;
        }

        .select-group {
            display: flex;
            gap: 12px;
            align-items: center;

            .selector_wrapper {
                min-width: calc(50% - 6px);
            }
        }

        .info-group {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
        }

        .btn-group {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: end;
        }
    }

    .selected-photo-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .text {
        color: #8F99A3;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .search_add-executor_active {
        cursor: pointer;
        color: #020617;
        outline: none;
    }

    .search-box-executor {
        position: relative;
        width: 100%;
        height: 48px;
        margin-bottom: 12px;

        .search_add-executor,
        .search_add-executor_active {
            position: relative;
            width: 100%;
            height: 48px;
            border-radius: 22px;
            padding: 10px;
            transition-duration: 1000ms;
            cursor: pointer;
            background-repeat: no-repeat;
            font-size: 12px;
            color: black;
            //border: 1px solid #F0F0F0;
            border: none;
            overflow: hidden;
            background-image: url('./img/executor_icon.svg');
            background-color: white;
            background-size: 48px;
            background-position: 0% 50%;
            padding-left: 56px;

            &::placeholder {
                color: #0009E8;
                font-family: NunitoSans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            &:focus {
                outline: none;
            }
        }

        .options-box,
        .options-box_active {
            position: absolute;
            left: 0;
            top: calc(100% + 4px);
            display: flex;
            width: 100%;
            flex-direction: column;
            border-radius: 16px;
            background-color: white;
            position: absolute;
            -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            right: -24px;
            cursor: pointer;
            transition: 1s;
            visibility: hidden;
            opacity: 0;
            transition: all 1s;
            overflow: hidden;
            max-height: 350px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .options-box_active {
            z-index: 1;
            visibility: visible;
            opacity: 1;
            transition: all 1s;
        }

    }

    .text_grey {
        font-size: 12px;
        color: #888888;
    }

    .text-no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        font-size: 12px;
    }

    .tooltip-executors-box {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        .close-executor {
            width: 16px;
            height: 16px;
            position: absolute;
            right: -15px;
            top: -4px;
            border-radius: 16px;
            border: none;
            cursor: pointer;
            background-image: url($close);
            background-color: #F5F5F5;
            background-repeat: no-repeat;
            background-size: 8px;
            background-position: center;
            opacity: 0;
            visibility: hidden;
            z-index: 2;
        }

        img {
            position: absolute;
            top: 0;
            right: -10px;
        }

        .tooltip-executors {

            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 34px;
            border-radius: 16px;
            padding: 8px 16px;
            background-color: white;
            -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        }

        .text-box {
            display: flex;
            flex-direction: column;

            .text {
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 180px;
            }

            .text_grey {
                font-size: 10px;
                color: grey;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 180px;
            }
        }


    }

    .tooltip-executors-box:hover {
        .tooltip-executors {
            opacity: 1;
            visibility: visible;
            position: absolute;
            top: 34px;
            border-radius: 16px;
            padding: 8px 16px;
            background-color: white;
            -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            transition: all 0.5s;
        }

        .close-executor {
            width: 16px;
            height: 16px;
            position: absolute;
            right: -15px;
            top: -4px;
            border-radius: 16px;
            border: none;
            cursor: pointer;
            background-image: url($close);
            background-color: #D9D9D9;
            background-repeat: no-repeat;
            background-size: 8px;
            background-position: center;
            opacity: 1;
            visibility: visible;
            transition: all 0.5s;
        }
    }

    .modal-add-task {


        .flag_red {
            stroke: #FA0F0F !important;

            path {
                stroke: #FA0F0F !important;
            }
        }

        .flag_green {
            stroke: #27BC62 !important;

            path {
                stroke: #27BC62 !important;
            }
        }

        .flag_yellow {
            stroke: #FA9C0F !important;

            path {
                stroke: #FA9C0F !important;
            }
        }

        .flag_blue {
            stroke: #1890FF !important;

            path {
                stroke: #1890FF !important;
            }
        }
    }
}