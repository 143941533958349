.modal-info-event {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
    width: 100%;
    min-width: 360px;
    min-height: 500px;
    background-color: white;
    padding: 32px 24px 24px;
    border-radius: 16px;
    max-height: calc(100vh - 120px);
    gap: 16px;

    .title {
        font-size: 16px;
        line-height: 24px;
    }

    .close {
        position: absolute;
        right: 24px;
        top: 24px;
    }

}