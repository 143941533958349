.copyright {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 28px;
    padding-right: 28px;
    gap: 4px;
    max-width: 370px;
    width: 100%;

    & .footer__link {
        text-align: center;
    }

    & .country {
        margin-top: 12px;
    }
}