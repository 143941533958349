.links-list-products-in-cabinet {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: white;
        border-radius: 16px;
        padding: 16px;
        width: 100%;
        height: 100%;

        .textarea-box {
            width: 100%;
            height: 100%;
        }
    }
}