.modal-product-preview {
    max-width: 1600px;
    width: 100%;
    min-width: 360px;
    gap: 24px;
    min-height: 360px;
    height: 100%;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
    position: relative;

    .text_description {
        overflow: hidden;
        color: #2E3552;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        p {
            word-break: break-word;
        }
    }

    .btn-close {
        position: absolute;
        border: none;
        background-color: inherit;
        width: 40px;
        height: 40px;
        top: 24px;
        right: 48px;
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 32px 48px;
        padding-bottom: 0px;

        .breadcrumbs-and-article {
            display: flex;
            width: 100%;
            gap: 12px;
            justify-content: space-between;
            align-items: center;

            .text_article {
                color: #64748B;
                font-family: Rubik;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-left: auto;

                span {
                    color: #020617;
                }
            }
        }

        .title {
            color: #020617;
            font-family: Rubik;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 38px;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        padding: 32px 48px;
        overflow: auto;
        padding-top: 0;

        .data-content {
            display: flex;
            width: 100%;
            gap: 40px;
            flex-wrap: nowrap;

            .slider-and-price {
                display: flex;
                min-width: 300px;
                max-width: 300px;
                flex-direction: column;
                gap: 12px;

                .img-content {
                    border: 2px solid #EDEFFB;
                    border-radius: 8px;
                    overflow: hidden;
                }

                .price-box {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 14px;
                    width: 100%;
                    height: auto;
                    border-radius: 16px;
                    padding: 24px;
                    background: linear-gradient(51deg, #F8F9FE 45.82%, rgba(248, 249, 254, 0.00) 118.42%);
                    border: 2px solid rgba(189, 198, 251, 0.56);
                    box-shadow: 6px 9px 30px 0px rgba(108, 129, 236, 0.05);

                    &_price {
                        display: flex;
                        width: fit-content;
                        min-height: 38px;
                        padding: 0px 4px;
                        gap: 8px;
                        border-radius: 4px;
                        background: #3900B1;

                        .text {
                            color: #FAF5FF;
                            font-family: IBMPlexSans;
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 38px;

                            span {
                                font-weight: 400;
                            }
                        }
                    }

                    &_old-price {
                        display: flex;
                        flex-direction: column;

                        .text {
                            width: fit-content;
                            color: #94A3B8;
                            font-family: IBMPlexSans;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 28px;
                            min-height: 29px;
                            position: relative;

                            &:before {
                                content: "";
                                border-bottom: 2px solid #475569;
                                position: absolute;
                                width: 100%;
                                height: 50%;
                                transform: rotate(-12deg);
                            }

                            span {
                                font-weight: 400;
                            }
                        }

                        .text_old-price {
                            color: var(--Slate-400, #94A3B8);
                            font-family: Rubik;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                        }
                    }
                }
            }

            .characteristics-group {
                display: flex;
                width: 100%;
                gap: 40px;

                .description-content {
                    display: flex;
                    flex-direction: column;
                    min-width: 228px;
                    width: 100%;
                    gap: 20px;

                    .title-medium {
                        color: #020617;
                        font-family: Rubik;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px
                    }

                    .characteristics-items {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        width: 100%;

                        .characteristics-item {
                            display: flex;
                            width: 100%;
                            justify-content: space-between;
                            align-items: flex-end;

                            .border {
                                width: 100%;
                                border-bottom: 1px solid #E0E7F2;
                            }

                            .text,
                            .text_grey {
                                color: #020617;
                                font-family: Rubik;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 16px;

                                &_grey {
                                    color: #64748B;
                                    font-family: Rubik;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 16px;
                                }
                            }
                        }
                    }
                }

            }

        }

        .info-block {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: calc(40% - 10px);
            width: 100%;

            .text_info {
                color: #2F506D;
                font-family: Rubik;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }

            &__content {
                display: flex;
                width: 100%;
                gap: 12px;
            }

            &__item {
                color: #6B8CA9;
                font-family: Rubik;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                padding: 16px;
                border-radius: 8px;
                background: #F8F7FD;

                &:nth-child(2) {
                    background: #F7FAFD;
                }
            }
        }

        .title {
            margin-top: 22px;
            margin-bottom: 12px;
            color: var(--all-text-secondary-text, #475569);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.07px;
        }

        .photo-group {
            display: flex;
            flex-direction: column;
            gap: 6px;
            max-width: 234px;
            min-width: 234px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .photo-box-main {
                width: 100%;
                height: 100%;
                display: flex;
                aspect-ratio: 3/4;
                border-radius: 4px;
                border: 1px solid var(--stroke-stroke, #E0E7F2);
                overflow: hidden;
                position: relative;

                &::after {
                    content: 'Главное фото';
                    top: 10px;
                    right: 10px;
                    position: absolute;
                    border-radius: 8px;
                    border: 1px solid var(--text-accent-blue, #1890FF);
                    background: #FFF;
                    display: flex;
                    padding: 4px;
                    justify-content: center;
                    align-items: center;
                    color: var(--main-menu-text-accent, #1890FF);
                    text-align: justify;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    /* 145.455% */
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            &__min {
                display: flex;
                width: 100%;
                gap: 6px;
                justify-content: space-between;
                flex-wrap: wrap;

                .photo-box {
                    display: flex;
                    max-width: 68px;
                    max-height: 68px;
                    width: 100%;
                    aspect-ratio: 1/1;
                    border-radius: 4px;
                    border: 1px solid var(--stroke-stroke, #E0E7F2);
                    overflow: hidden;
                    position: relative;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: auto;
                    }
                }

                .video-box {
                    display: flex;
                    max-width: calc(50% - 4px);
                    border-radius: 4px;
                    overflow: hidden;
                    position: relative;

                    video {
                        width: 100% !important;
                        height: auto !important;
                    }
                }

                .btn {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 12px;
                    width: calc(50% - 4px);
                    height: 60px;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    background-color: rgb(50 40 40 / 40%);
                    border: none;
                    color: var(--white, #FFF);
                    text-align: center;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                    /* 100% */
                    vertical-align: top;
                    letter-spacing: -0.24px;

                    &:hover {
                        background-color: rgb(50 40 40 / 60%);
                        transition: 0.5s;
                    }

                    img {
                        width: 16px;
                        height: auto;
                    }
                }
            }
        }
    }



    .text {
        color: var(--main-menu-secondary-text, #475569);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .rating-box {
        display: flex;
        gap: 8px;
        align-items: center;
    }
}