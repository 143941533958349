.top-keywords {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    &__header {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 12px;

        .title_main {
            color: #020617;
            font-family: Rubik;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.56px;
        }

        .text_description {
            color: #2E3552;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .toolbar {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
    }

}