.checkbox-field {
    display: flex;
    width: 100%;
    padding: 16px 20px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: white;
    min-height: 56px;
    margin-top: 24px;
    justify-content: space-between;

    .text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: var(--main-menu-primary-text, #020617);
        /* Body/S/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
    }
}