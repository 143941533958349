.modal-add-type-notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 560px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px 24px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        color: var(--text-primary-blue, #020617);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 80% */
    }

    


    .btn__blue {
        margin-top: 30px;
        max-width: 250px;
        height: 56px;
    }
}