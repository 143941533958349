.analytical-collections {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title_main-page {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    .toolbar-top {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        align-items: flex-start;
        justify-content: space-between;
    }

    .charts-and-metrics {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .btn-group-panel {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
    }

    .tabs {
        flex-wrap: wrap;

        .tabs__item,
        .tabs__item_active {
            min-height: 45px;
           max-width: 175px;

            // &:nth-child(1) {
            //     max-width: 70px;
            // }

            // &:nth-child(2) {
            //     max-width: 160px;
            // }

            // &:nth-child(3) {
            //     max-width: 97px;
            // }

            // &:nth-child(4) {
            //     max-width: 105px;
            // }

            // &:nth-child(5) {
            //     max-width: 142px;
            // }

            // &:nth-child(6) {
            //     max-width: 151px;
            // }

            // &:nth-child(7) {
            //     max-width: 105px;
            // }

            // &:nth-child(8) {
            //     max-width: 84px;
            // }

            // &:nth-child(9) {
            //     max-width: 119px;
            // }
        }
    }

    .metrics-box {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 12px;
        gap: 8px;
    }

    .charts-container {
        width: 100%;
        display: flex;
        background-color: white;
        border-radius: 8px;

        .charts-group {
            display: flex;
            width: 100%;
            gap: 2%;

            .line_chart {
                width: 60%;
                margin: 0;
                padding: 0;
            }

            .cup-component {
                width: 38%;
            }
        }
    }
}