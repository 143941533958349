.modal-filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    max-width: fit-content;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    max-height: 100vh;
    border-radius: 8px 0 0 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    

    &__header {
        width: 100%;
        padding: var(--8, 8px) var(--64, 64px) var(--8, 8px) var(--48, 48px);
        border-bottom: 1px solid var(--Stroke-Primary, #EDEFED);

        .title {
            color: #0B110B;
            font-family: NunitoSans;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #F4F6F4;
        padding: 32px 48px;
        height: calc(100% - 60px);
        gap: 20px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__bottom-content {
        width: 100%;
        padding: 32px 48px;
    }

    .filter-item {

        &__placeholder{
            line-height: 13px;
        }

        .input-container {
            max-width: 170px;
            min-width: 170px;
        }
    }

}