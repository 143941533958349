.bar-chart-ver2 {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: 250px;
    max-height: calc(98px*2 + 15px);
    min-width: 500px;
    flex-grow: 0;
    transition: 0.5s;
    opacity: 1;
    visibility: visible;
}