.single-date-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #F2F2F4;
    min-width: 240px;
    max-width: 240px;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 40px;

    input {
        border: none;
        outline: none;
        background-color: inherit;
    }

    .react-datepicker__month-container,
    .react-datepicker {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 8px;

        * {
            box-shadow: none;
        }
    }
}

.date-picker-custom {
    display: flex;
    gap: 20px;
    align-items: end;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    border: none;
    width: max-content;
    overflow: visible;
    align-items: start;

    // div {
    //     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    //     border-radius: 8px;

    //     * {
    //         box-shadow: none;
    //     }
    // }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        line-height: 1.4rem;
        width: 2rem;
    }

    .text {
        color: #5F5F5F;
        font-size: 14px;
    }


}