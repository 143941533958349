.steps-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 28px;
    }

    .img-box {
        display: flex;
        justify-content: center;
    }

    .step-item {
        display: flex;
        gap: 12px;
        height: auto;
        cursor: pointer;
        position: relative;

        &__content {
            width: 100%;
        }

        .num-step {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #F2F4F7;
            border-radius: 50%;
            width: 32px;
            min-width: 32px;
            min-height: 32px;
            height: 24px;
            color: #00284D;
            font-family: IBMPlexSans;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }

        .text {
            color: var(--text-primary-black, #020617);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }

        .text_grey {
            color: var(--text-secondary-black, #53616E);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 38px;
            width: 2px;
            height: calc(100% - 16px);
            left: 15px;
            background-color: #E4E7EC;
        }

        &:last-of-type {
            &::after {
                display: none;
            }
        }
    }

}