$--grey--border: #D1D5DB;
$--bcg: #fff;
$--text: #0B110B;
$--grey: #5A6577;

// Окно чата

.chat_message_page {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    border-radius: 10px;
    border: 1px solid $--grey--border;
    background-color: $--bcg;
    max-height: 80vh;

    // Панель выбора проектов

    &__side_panel {
        display: flex;
        flex-direction: column;
        gap: 18px;
        border-right: 1px solid $--grey--border;
        height: 100%;
        width: 216px;
        min-width: 216px;
    }

    &__title {
        font-family: NunitoSans;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding: 16px;
        padding-bottom: 0;
    }

    &__project_item,
    &__project_item_active {
        display: flex;
        padding: 6px 8px;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        cursor: pointer;
        color: #6B7280;
        font-family: NunitoSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;

        &:hover {
            transition: 0.5s;
            background: #F0FDF4;
        }

        &_active {
            background-color: #60CB71;
            color: #fff;

            &:hover {
                transition: 0.5s;
                background-color: #60CB71;
                color: #fff;
            }
        }
    }

    &__project_list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        overflow: auto;
        padding: 16px;
    }

    // Список чатов

    &__list_message {
        border-right: 1px solid $--grey--border;
        width: 40%;
        height: 100%;
        
    }

    &__container_list_message {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: auto;
        height: 100%;
        max-height: calc(100% - 184px);
    }

    &__header {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 18px;
        padding: 20px;

        .input-field-wrapper {
            max-width: 100%;
        }
    }

    &__btn_group {
        display: flex;
        gap: 12px;
    }

    // Активный чат

    &__current_chat {
        width: 60%;
        height: 100%;
        overflow: hidden;
    }

    &__header_message {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 12px 20px;
        width: 100%;
        min-height: 67px;
        border-bottom: 1px solid $--grey--border;
        position: relative;
    }
    
    &_close_btn{
        position: absolute;
        right: .5em;
        top: .25em;
        cursor: pointer;
    }

    &__user_icon {
        width: 42px;
        height: 42px;
        border-radius: 50%;
    }

    &__name_chat {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #111827;
        font-family: NunitoSans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }

    &__content_message {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px;
        gap: 10px;
        overflow: auto;
        max-height: calc(100% - 67px);
    }

    &__content_message_send {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        overflow: auto;
        max-height: calc(100% - 67px);
    }
}

// Превью

.message_preview_v1,
.message_preview_v1_active {
    display: flex;
    gap: 10px;
    padding: 16px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid $--grey--border;
    background: #FFF;
    cursor: pointer;

    .employees-container {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;

        .sum_executor {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #F1F5F9;
            color: #2E3552;
            text-align: center;
            font-family: NunitoSans;
            font-size: 10px;
            font-style: normal;
            font-weight: 800;
            line-height: 8px;
            margin-left: 8px;
            cursor: pointer;

            span {
                color: rgba(46, 53, 82, 0.60);
            }
        }

        .tooltip-container {
            margin-left: -6px;

            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }

    .btn-group {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 11px;
        border-radius: 8px;
        background: #ffffff;

        .btn-edit,
        .btn-delete {
            border: none;
            background-color: inherit;
            width: 18px;
            height: 18px;
            min-width: 18px;
        }
    }

    &_active {
        background: #F0FDF4;
    }

    &:hover {
        transition: 0.5s;
        background: #F0FDF4;
    }

    &__content {
        display: flex;
        align-items: center;
        width: calc(100% - 42px - 10px);
        gap: 4px;
    }

    &__top,
    &__bottom {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

    &__top{
        .unread_count{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: rgb(93, 163, 255);
            padding: .25em;
            font-size: 16px;
            // line-height: 1em;
            color: white;
        }
    }

    &__title {
        overflow: hidden;
        color: $--text;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    &__date {
        color: $--grey;
        font-family: NunitoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        white-space: nowrap;
    }

    &__message {
        overflow: hidden;
        white-space: nowrap;
        color: #747F90;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    &__sum_message {
        display: flex;
        width: 20px;
        height: 20px;
        min-width: 20px;
        padding: 4px 3px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #4E75FF;
        color: #FFF;
        font-family: NunitoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
    }
}

// Сообщение

.message_item_v1,
.message_item_user_v1 {
    width: 70%;
    display: flex;
    padding: 12px 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px 8px 8px 0px;
    background: #F3F4F6;

    &__title {
        color: #000;
        font-family: NunitoSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    &__link {
        color: #0009E8;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-decoration-line: underline;
    }

    &__text {
        color: #000;
        font-family: NunitoSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    &__date {
        color: #5C7189;
        font-family: "Golos Text";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    &__img {
        width: 100%;
        height: 100%;
    }
}

.message_item_user_v1 {
    margin-left: auto;
    border-radius: 8px 8px 0px 8px;
    background: #DCFCE7;
}