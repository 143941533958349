@import 'src/common/styles/svg_img.scss';

.competitors-inks {
    .rich__select-box {
        max-width: 100%;
    }
}

.competitor_link_header_span{
    width: 100%;
    text-align: left;
}

.competitors_textarea{
    width: 100%;
    height: calc(100%);
    outline: 1px solid #AAAAAA;
    border: none;
    border-radius: 16px;
    padding: 8px 16px;
    font-size: 14px;
    resize: none;
    padding: 8px 16px;

    &:focus {
        outline: 1px solid #1890FF;
    }
}

.competitor_link_main_span {
    font-size: 14px;
}

.competitor_link_main_wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #AAAAAA;
    padding-bottom: 16px;

    .btn__green {
        margin: 0;
    }
}

.competitor_link_main_add_btn{
    display: inline-block;
    background-color: #27BC62;
    padding: .5em 1em;
    border-radius: 16px;
    color: white;
    cursor: pointer;
}

.btn__white-icon {
    cursor: pointer;
    width: 176px;
    min-width: 176px;
    color: #1890FF;
    background-image: url($upload);
    background-repeat: no-repeat;
    background-position: center right 13px;
    padding-right: 30px;
    height: 32px;
    border: 1px solid #F0F0F0;
    border-radius: 16px;
    font-size: 14px;
    background-color: #FFFFFF;

    &:hover {
        -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
    }
}

.competitors-links {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: white;
    border-radius: 16px;

    &__header {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 100%;
    }
}