// .transfer-of-prices {
//     display: flex;
//     justify-content: start;
//     align-items: start;
//     margin-left: auto;
//     margin-right: auto;
//     width: auto;
//     gap: 50px;

//     &__header {
//         display: flex;
//         flex-direction: column;
//         border-radius: 8px;
//         overflow: hidden;
//         min-width: 500px;
//         max-width: 500px;
//         border: 1px solid #E0E7F2;

//         .name-container {
//             padding: 16px 24px;
//             background-color: white;

//             .title_blue {
//                 color: var(--button-primary-default, #1890FF);
//                 text-align: center;
//                 font-family: Inter;
//                 font-size: 14px;
//                 font-style: normal;
//                 font-weight: 500;
//                 line-height: normal;
//             }
//         }

//         .col-info-group {
//             display: flex;
//             border-top: 1px solid #E0E7F2;

//             .col-info {
//                 width: 50%;
//                 padding: 10px 12px;
//                 margin-top: 6px;
//                 margin-bottom: 6px;

//                 &:nth-child(2) {
//                     border-left: 1px solid #E0E7F2;
//                      margin-left: -1px;
//                 }
//             }
//         }

//         .text {
//             color: #020617;
//             text-align: center;
//             font-family: Inter;
//             font-size: 12px;
//             font-style: normal;
//             font-weight: 500;
//             line-height: normal;
//         }
//     }

//     &__content {
//         display: flex;
//         flex-direction: column;
//         gap: 8px;
//         width: auto;

//         .available-warehouses,
//         .upgradable-warehouses {
//             display: flex;
//             padding: 32px 24px;
//             flex-direction: column;
//             align-items: center;
//             gap: 20px;
//             border-radius: 8px;
//             border: 1px solid #E0E7F2;
//             background: #FFF;
//             box-shadow: 0px 15px 20px 0px rgba(30, 41, 59, 0.06);
//             max-width: 380px;
//             width: 380px;
//             width: 100%;
//             min-height: 600px;
//             min-width: 223px;
//             align-items: flex-start;

//             .warehouses-list {
//                 display: flex;
//                 flex-direction: column;
//                 gap: 16px;
//                 width: 100%;
//                 height: 100%;
//                 overflow-y: auto;
//                 overflow-x: hidden;
//                 max-height: 500px;

//                 &::-webkit-scrollbar {
//                     width: 0;
//                 }

//                 .card-warehouses {
//                     display: flex;
//                     width: auto;
//                     min-width: 300px;
//                     padding: 16px 24px;
//                     flex-direction: column;
//                     gap: 8px;
//                     border-radius: 8px;
//                     border: 1px solid #E0E7F2;
//                     background: #F5F7F8;
//                     cursor: grab;

//                     .marketplace-info {
//                         display: flex;
//                         gap: 16px;
//                         align-items: center;

//                         .text {
//                             display: -webkit-box;
//                             -webkit-line-clamp: 2; // количество строк
//                             -webkit-box-orient: vertical;
//                             overflow: hidden;
//                             hyphens: auto;
//                             color: #020617;
//                             font-family: Inter;
//                             font-size: 14px;
//                             font-style: normal;
//                             font-weight: 400;
//                             line-height: normal;
//                         }
//                     }

//                     .warehouse-name {
//                         display: flex;
//                         gap: 12px;
//                         color: #53616E;
//                         font-family: Inter;
//                         font-size: 14px;
//                         font-style: normal;
//                         font-weight: 400;
//                         line-height: normal;

//                         &::before {
//                             content: '';
//                             display: inline-block;
//                             min-width: 16px;
//                             width: 16px;
//                             height: 16px;
//                             background-image: url('./img//warehouse_icon.svg');
//                             background-repeat: no-repeat;
//                             background-size: 16px;
//                         }
//                     }

//                     &:active {
//                         border: 1px solid #95CBFE;
//                     }
//                 }
//             }

//             .green-btn {
//                 align-self: flex-end;
//             }
//         }
//     }

//     &__side-panel {
//         display: flex;
//         flex-direction: column;
//         min-width: 224px;
//         max-width: 224px;
//         padding: 20px;
//         gap: 28px;
//         border-radius: 8px;
//         background: #ffffff;
//         border: 1px solid #E0E7F2;

//         .step-item {
//             display: flex;
//             gap: 12px;
//             height: auto;
//             cursor: pointer;

//             .img-container {
//                 width: 24px;
//                 min-width: 24px;
//                 min-height: 24px;
//                 height: 24px;
//             }

//             .text {
//                 color: var(--text-secondary-black, #53616E);
//                 font-family: Inter;
//                 font-size: 12px;
//                 font-style: normal;
//                 font-weight: 500;
//                 line-height: 16px;
//             }

//             .text_grey {
//                 color: var(--text-teritary-black, #8F99A3);
//                 font-family: Inter;
//                 font-size: 12px;
//                 font-style: normal;
//                 font-weight: 400;
//                 line-height: 16px;
//             }
//         }
//     }
// }

.transfer-of-prices {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: start;
    align-items: start;
    width: auto;
    gap: 24px;

    .content-btn {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 8px;
        align-items: center;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: center;
        border-radius: 8px;
        overflow: hidden;
        min-width: 500px;
        max-width: 500px;
        border: 1px solid #E0E7F2;
        padding: 12px;
        border-radius: 8px;
        background: #FFF;

        /* small_black */
        box-shadow: 0px 4px 4px 0px rgba(30, 41, 59, 0.06);

        .name-container {
            display: flex;
            gap: 16px;
            justify-content: space-between;
            background-color: white;
            width: calc(50% - 12px);

            .btn-edit {
                width: 16px;
                min-width: 16px;
                height: 16px;
                background-image: url('./img/edit_icon.svg');
                background-repeat: no-repeat;
                background-size: 16px;
                border: none;
                background-color: inherit;
            }

            .title_blue {
                cursor: pointer;
                width: 100%;
                text-align: center;
                color: var(--button-primary-default, #1890FF);
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        .text {
            color: #020617;
            text-align: center;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: auto;

        .available-warehouses,
        .upgradable-warehouses {
            display: flex;
            padding: 12px 8px;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            border-radius: 8px;
            border: 1px solid #E0E7F2;
            background: #F5F7F8;
            box-shadow: 0px 15px 20px 0px rgba(30, 41, 59, 0.06);
            max-width: 240px;
            width: 240px;
            position: relative;

            .static-info {
                position: absolute;
                top: -12px;
                left: 16px;
                display: flex;
                padding: 2px;
                align-items: flex-start;
                gap: 8px;
                position: absolute;
                left: 16px;
                border-radius: var(--4, 4px);
                background: var(--slate-100, #F1F5F9);
                color: var(--black-400, #8F99A3);
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            &__header {
                width: 100%;
                min-height: 126px;

                .title {
                    color: #475569;
                    text-align: center;
                    font-family: Inter;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .text {
                    margin-top: 20px;
                    color: #8F99A3;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .blue-btn {
                    margin-top: 20px;
                }

                .strategy-container {
                    display: flex;
                    flex-direction: column;
                    gap: 28px;
                    width: 100%;
                    align-items: center;

                    .strategy-name {
                        color: #53616E;
                        text-align: center;
                        font-family: Inter;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                    }

                    .changed-strategy-btn {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        color: #1890FF;
                        text-align: center;
                        font-family: Inter;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;

                        &::after {
                            content: '';
                            display: inline-block;
                            min-width: 16px;
                            width: 16px;
                            height: 16px;
                            background-image: url('./img/edit.svg');
                            background-repeat: no-repeat;
                            background-size: 16px;
                        }
                    }
                }


            }

            .warehouses-list {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 60vh;
                min-height: 60vh;

                &::-webkit-scrollbar {
                    width: 0;
                }

                .no-list {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    align-items: center;
                    width: 100%;
                    height: fit-content;
                    margin-top: 200px;
                    justify-content: center;

                    img {
                        width: 45px;
                        height: 45px;
                    }

                    .text {
                        color: #94A3B8;
                        text-align: center;
                        font-family: Inter;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                    }
                }

                .card-warehouses {
                    display: flex;
                    justify-content: center;
                    width: auto;
                    padding: 12px;
                    flex-direction: column;
                    gap: 8px;
                    border-radius: 12px;
                    border: 1px solid #E0E7F2;
                    background: #ffffff;
                    cursor: grab;
                    min-height: 84px;

                    .marketplace-info {
                        display: flex;
                        gap: 16px;
                        align-items: center;

                        img {
                            width: 32px;
                            height: 32px;
                        }

                        .text {
                            display: -webkit-box;
                            -webkit-line-clamp: 3; // количество строк
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            hyphens: auto;
                            color: #020617;
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                    .warehouse-name {
                        display: flex;
                        gap: 12px;
                        color: #53616E;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;

                        &::before {
                            content: '';
                            display: inline-block;
                            min-width: 16px;
                            width: 16px;
                            height: 16px;
                            background-image: url('./img//warehouse_icon.svg');
                            background-repeat: no-repeat;
                            background-size: 16px;
                        }
                    }

                    &:active {
                        border: 1px solid #95CBFE;
                    }
                }

                &[loading='true'] {
                    pointer-events: none;
                    opacity: 0.3;
                }
            }

            .green-btn {
                align-self: flex-end;
            }
        }
    }

    .column-group {
        display: flex;
        gap: 20px;
    }

    &__side-panel {
        display: flex;
        flex-direction: column;
        min-width: 224px;
        max-width: 224px;
        gap: 24px;

        .step-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 20px;
            gap: 28px;
            border-left: 1px solid #92CEA8;
        }

        .step-item {
            display: flex;
            gap: 12px;
            height: auto;
            cursor: pointer;

            .num-step {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #DCFCE7;
                border-radius: 50%;
                width: 24px;
                min-width: 24px;
                min-height: 24px;
                height: 24px;
                color: var(--13, #393);
                font-family: IBMPlexSans;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            .text {
                color: var(--text-secondary-black, #53616E);
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }

            .text_grey {
                color: var(--text-teritary-black, #8F99A3);
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }
    }
}