.hint-page {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__title {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
    }

    &__card-content {
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
    }
}

.card-recommendation {
    display: flex;
    width: 324px;
    padding: 12px 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    border-radius: 10px;
    background: #FFF;

    &__header {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 8px;
    }

    &__info-panel {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &__title {
        overflow: hidden;
        color: #0B110B;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: NunitoSans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px
    }

    &__status-read {
        display: flex;
        height: 18px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        text-align: center;
        font-family: NunitoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
    }

    .read {
        background: #51CA64;
        color: #F8F9FE;
    }

    .no-read {
        background: #F0F2F4;
        color: #0B110B;
    }

    &__status-criticality {
        display: flex;
        padding: 2px 6px;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        border: 1px solid #F0F2F4;
        font-family: NunitoSans;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
    }

    .status-green {
        color: #45A153;

        svg {
            path {
                fill: #45A153;
            }
        }
    }


    .status-red {
        color: #E56868;

        svg {
            path {
                fill: #E56868;
            }
        }
    }

    .status-orange {
        color: #F3A34E;

        svg {
            path {
                fill: #F3A34E;
            }
        }
    }

    &__reason,
    &__solution {
        display: flex;
        padding: 10px 12px;
        flex-direction: column;
        gap: 10px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 10px;
        background: #F9FAFB;
        width: 100%;
    }

    &__title-reason,
    &__title-solution {
        color: #F3A34E;
        font-family: NunitoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
    }

    &__title-solution {
        color: #45A153;
    }

    &__text {
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }
}