@import 'src/common/styles/svg_img.scss';

$outside-margin-px: 15px;

.category-breadcrumbs__input-container {
    display: flex;
    gap: 12px;
    align-items: center;
    pointer-events: none;
    height: 40px;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 40px 20px;
    z-index: 10000;
    transition: 0.5s;

    .input-field-wrapper {
        width: 100%;
        pointer-events: all;
        max-width: 100%;
    }

    .close-btn {
        width: 32px;
        height: 32px;
        background-image: url('./img/close_icon.svg');
        border: none;
        background-color: inherit;
        // position: absolute;
        background-position: center;
        top: 25px;
        right: 20px;
        pointer-events: all;
    }
}

.filter-bread-crumbs {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    padding: 32px 20px;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    z-index: 4000;
    box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.08);
    height: 100vh;

    .header-container {
        display: flex;
        position: relative;
    }

    .tabs-container {
        display: flex;
        gap: 15px;
        height: calc(100% - 70px);
        transition: .3s;

        &.loading {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    .apply-btn {
        margin-right: $outside-margin-px;
        margin-top: 15px;
        margin-left: auto;
    }

    .blue-btn {
        margin-top: 30px;
    }
}

.filter-static-option {
    white-space: nowrap;
    cursor: pointer;
}

.filter-bread-crumbs-tab {
    flex: 1;
    min-height: min-content;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 242px;
    max-width: 242px;

    p {
        width: 100%;
        color: #020617;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border-radius: 4px;
        padding: 8px;
        cursor: pointer;

        &:hover,
        &.selected {
            transition: 0.3s;
            background: #E4E5F9;
            color: #3900B1;
        }
    }
}

.crumb-item {
    display: flex;
    align-items: center;
    background-color: white;
    border: 2px solid blue;
    padding: 12px 4px;
    border-radius: 8px;
}

.category-breadcrumbs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: auto;

    &__drop-content {
        display: flex;
        top: 0;
        padding: 20px;
        padding-top: 70px;
        background: #fff;
        position: absolute;
        width: calc(100vw);
        height: auto;
        max-height: 80vh;

    }

    &__scroll-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        overflow: auto;
    }

    &__row {
        flex-direction: row;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        color: #020617;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        white-space: nowrap;
        cursor: pointer;

        &::after {
            content: '';
            display: inline-block;
            width: 24px;
            height: 17px;
            background-image: url('./img/arrow_blue.svg');
            background-repeat: no-repeat;
            block-size: 24px;
            background-position: center;
        }

        &:nth-last-of-type(1) {
            cursor: default;
            color: #020617;

            &::after {
                content: '';
                display: none;
            }
        }
    }
}