.event-item {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    gap: 8px;
    background-color: #1890FF;
    border-radius: 4px;
    overflow: hidden;
    padding: 4px;
    padding-left: 12px;
    align-items: center;
    cursor: pointer;
    border: 1px solid #EAEBF0;
    flex-direction: column;
    justify-content: center;
    color: white;

    // &::before {
    //     content: '';
    //     display: block;
    //     margin-right: 12px;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     display: block;
    //     width: 4px;
    //     height: 100%;
    //     border-radius: 5px, 0px, 0px, 5px;
    //     background-color: #1890FF;
    // }

    p {
        font-size: 10px;
        font-weight: 500;
        white-space: nowrap;
    }
}