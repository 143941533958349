.modal-delete-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 460px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    gap: 24px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        color: #020617;
        text-align: center;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; 
    }

    .btn-group {
        display: flex;
        width: 100%;
        gap: 12px;

    }
}