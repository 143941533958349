.сard-metrics {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    width: 196px;
    min-width: 196px;
    height: 133px;
    border-radius: 12px;
    background: #FFF;
    padding: 12px;
    border-radius: 8px;
    border-bottom: 4px solid #D2CEFF;
    background: #FFF;

    .title_min {
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
    }

    .text {
        color: #0B110B;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    .dynamic_positive {
        display: flex;
        padding: 2px 4px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        background: #E1F9E5;
        color: #218931;
        font-family: Inter;
        font-size: 12.554px;
        font-style: normal;
        font-weight: 400;
        line-height: 16.739px;
    }
}