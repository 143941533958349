.container.modal-promotion-rules {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    max-width: 1220px;
    width: 100%;
    min-width: 622px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 40px);
    height: 100%;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);


    &__header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        gap: 60px;
        padding: 20px 24px;

        .title {
            white-space: nowrap;
            margin-left: auto;
            margin-right: auto;
            color: #020617;
            font-family: Rubik;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px
        }
    }

    &__toogle-checkbox {
        display: flex;
        align-items: center;
        width: auto;
        gap: 12px;
    }

    &__text_label {
        color: #636D65;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    &__text_status {
        @extend .modal-promotion-rules__text_label;

        &_active {
            color: #22C55E;
        }

        &_disactive {
            color: red;
        }
    }

    &__toolbar-top,
    &__toolbar-medium {
        width: 100%;
        display: flex;
        gap: 12px;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px 24px;
        border-top: 2px solid #EDEFFB;
        border-bottom: 2px solid #EDEFFB;
    }

    &__toolbar-medium {
        border-top: none;
    }

    &__btn-close {
        background-color: inherit;
        border: none;
        cursor: pointer;
    }

    .promotion-rules-parametr {
        display: flex;
        flex-direction: column;
        position: absolute;
        border-radius: var(--8, 8px);
        background: #FFF;
        box-shadow: 0px 3px 20px 0px rgba(0, 17, 45, 0.20);
        z-index: 10;
        align-items: center;
        max-width: calc(418px + 56px*2);
        top: 164px;

        &__header {
            width: 100%;
            border-bottom: 2px solid #EDEFFB;
            padding: 20px 32px;

            .title_main {
                color: var(--slate-950, #020617);
                font-family: Rubik;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: -0.56px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            padding: 20px 32px;

            .title {
                color: #020617;
                font-family: Rubik;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }

        .input-box {
            margin-bottom: 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        min-width: 622px;
        height: 100%;
        max-height: calc(100% - 20px);
        gap: 16px;
        width: 100%;
        padding: 20px 56px;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .selector_wrapper {
            margin-top: 8px;
        }
    }

    .toolbar {
        display: flex;
        align-items: center;
        gap: 8px;

        .btn-add {
            width: 32px;
            height: 32px;
            min-width: 32px;
            background-image: url('./img/user_plus.svg');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: center;
            border: 1px solid #E0E7F2;
            border-radius: 50%;
            margin-left: 8px;
            cursor: pointer;
        }

        .text {
            color: #8F99A3;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }

        .icon-group {
            display: flex;
            align-items: center;

            .img {
                margin-left: -6px;
                width: 24px;
                height: 24px;

                &:nth-child(1) {
                    margin-left: 0;
                }
            }
        }
    }

    .rules-content {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        width: auto;
        gap: 20px;

        .input-box {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }

        &__tooltip {
            display: flex;
            width: 200px;
            padding: 16px;
            flex-direction: column;
            gap: 8px;
            border-radius: 8px;
            background: #EFF6FF;
            color: #636D65;
            font-family: Rubik;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;

            .checkbox-status input[type=checkbox]:checked+.checkbox-status-switch,
            .checkbox-status_disabled input[type=checkbox]:checked+.checkbox-status-switch {
                border: 2px solid #CB84EC;
                background: #CB84EC;
            }
        }

        .content-btn {
            display: flex;
            width: 100%;
            justify-content: center;
            gap: 8px;
            align-items: center;
        }

        &__content {
            display: flex;
            align-items: flex-start;
            gap: 16px;
            margin-left: auto;
            margin-right: auto;
            min-width: calc(500px + 12px*2 + 400px);

            .col-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 8px;
                border: 2px solid #EDEFED;
                background: #FAFBFA;
                max-width: 240px;
                width: 240px;
                position: relative;

                &__header {
                    display: flex;
                    width: 100%;
                    padding: 12px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-bottom: 2px solid #EDEFED;
                    border-radius: 8px 8px 0px 0px;
                    background: #FFF;
                    min-height: 76px;

                    .title {
                        color: #64748B;
                        text-align: center;
                        font-family: Inter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin: 0;

                        span {
                            color: #020617;
                        }
                    }

                    .info-icon {
                        cursor: pointer;
                    }
                }

                &__btn_settings {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 28px;
                    height: 28px;
                    border-radius: 4px;
                    border: 1px solid #EDEFED;
                    background: #FAFBFA;

                    &:hover {
                        border: 1px solid #BBF7D0;
                    }

                    &:active {
                        border: 1px solid #00B45E;
                    }
                }

                &__list {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 60vh;
                    min-height: 60vh;
                    padding: 8px;

                    &::-webkit-scrollbar {
                        width: 0;
                    }

                    .no-list {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        align-items: center;
                        width: 100%;
                        height: fit-content;
                        margin-top: 200px;
                        justify-content: center;

                        img {
                            width: 45px;
                            height: 45px;
                        }

                        .text {
                            color: #94A3B8;
                            text-align: center;
                            font-family: Inter;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                        }
                    }



                    &[loading='true'] {
                        pointer-events: none;
                        opacity: 0.3;
                    }
                }

                &__card {
                    display: flex;
                    width: auto;
                    padding: 12px;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 12px;
                    border-radius: 12px;
                    border: 2px solid #EDEFED;
                    background: #ffffff;
                    cursor: grab;
                    min-height: 88px;

                    .text {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        hyphens: auto;
                        color: #020617;
                        font-family: Rubik;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        min-height: 48px;
                    }

                    .status-content {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        .status {
                            display: flex;
                            min-height: 18px;
                            height: 18px;
                            padding: 4px 8px;
                            align-items: center;
                            justify-content: center;
                            border-radius: 4px;
                            background: #F1F5F9;
                            color: var(--slate-400, #94A3B8);
                            font-family: Rubik;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 14px;
                        }

                        .card-disactive {
                            @extend .status;
                            background: #F1F5F9;
                            color: #94A3B8;
                        }

                        .card-active {
                            @extend .status;
                            background-color: #86EFAC;
                            color: #15803D;
                        }
                    }
                }

                .green-btn {
                    align-self: flex-end;
                }
            }
        }

        .column-group {
            display: flex;
            gap: 16px;
        }

        &__side-panel {
            display: flex;
            flex-direction: column;
            min-width: 224px;
            max-width: 224px;
            gap: 24px;
        }
    }
}