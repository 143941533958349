.landing {

    &__description-box {
        height: 100px;
        margin-top: 8px;
    }

    &__content-changes {
        flex-direction: column;
        padding: 8px;
        opacity: 1;
        transition: opacity 1s;
        max-width: 400px;
        top: 0;
        height: auto;
        position: absolute;
        height: 200px;

        & .title {
           
        }

        & .text {
            margin-top: 24px;
        }
    }

    .hidden {
        max-width: 100%;
        flex-direction: column;
        padding: 8px;
        opacity: 0;
        position: absolute;
    }

    &__img-content {
        display: flex;
        width: 100%;
        height: 130px;
    }

    &__img-box {
        display: flex;
        align-items: center;
        width: 50%;
        height: 100%;

        & img {
            max-width: 180px;
        }
    }

    &__content-bottom {
        margin-top: auto;
        position: relative;
        z-index: 1;
    }


}