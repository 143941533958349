.no-data-content {
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 800px;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;

    &__title {
        color: #0F172A;
        text-align: center;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: -0.2px;
    }

    &__text {
        color: #68727D;
        text-align: center;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}