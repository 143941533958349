.modal-miltilevel-sidebar-settings {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  position: relative;
  z-index: 1000;
  display: flex;
  max-width: 1200px;
  width: 100%;
  min-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: var(--white, #f6f7f6);
  box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
  max-height: calc(100vh - 40px);
  height: 100%;
  overflow: auto;

  &__menu-item {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &__drop-box {
    width: 100%;
    padding-left: 10px;
  }

  &__title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #020617;
    font-family: NunitoSans;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.56px;
  }

  &__group {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 10px;
    overflow-y: auto;
    background-color: #f6f7f6;
    border-radius: 8px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 350px;
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    height: fit-content;
  }

  &__top-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;

    .input-dinamyc-placeholder {
      max-width: 264px;
    }
  }
}

.sidebar-miltilevel-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &__btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    background-color: #22c55e;
    font-size: 12px;
    line-height: 16px;
    color: white;
    font-weight: 800;
    border: none;
    border-radius: 50%;

    &:hover {
      transition: 0.5s;
      background-color: #16a34a;
    }
  }

  &__btn-container {
    display: flex;
    position: relative;
  }

  &__btn-drop {
    width: 16px;
    height: 16px;
    border: none;
    background-color: inherit;
  }

  &__btn-delete,
  &__btn-edit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: none;
    background: inherit;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__label-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  }

  &__label {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    padding: 4px;
    border-radius: 8px;

    &_active {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 8px;
      padding: 4px;
      border-radius: 8px;
      background-color: rgb(210, 218, 226);
      color: #b147e2;
    }
  }

  &__title {
    display: flex;
    font-size: 18px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    color: var(--Text-Primary-Black, #0b110b);
    font-family: NunitoSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    padding: 7px 12px;
    cursor: pointer;
    position: relative;

    &:dragenter {
      background-color: #16a34a;
    }

    &:hover {
      color: #b147e2;

      .sidebar-miltilevel-settings____top-line {
        background-color: #16a34a;
      }
    }
  }

  .sidebar-miltilevel-settings__title[contenteditable='true'] {
    outline: none;
    background-color: white;
    outline: 1px solid #16a34a;
    border-radius: 8px;
  }

  &__input {
    padding: 8px;
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 4px;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid #16a34a;

    &:focus {
      outline: 2px solid #16a34a;
    }
  }

  .drop-placeholder,
  .drop-placeholder_hide {
    width: 100%;
    transition: min-height, 0.5s;
    min-width: 0px;
    border: none;
    height: 0px;
    min-height: 0px;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .drop-placeholder {
    border: 1px dashed #b2d4be;
    min-height: 16px;

    &.hover {
      background-color: #f5fdf8;
    }
  }

  &__drop,
  &__drop_active {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    max-height: 0;

    &_active {
      max-height: 100%;
    }
  }

  &__top-line,
  &__bottom-line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #fff;
    top: 0;
  }

  &__bottom-line {
    bottom: 0;
  }

  &__block {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    padding: 7px 0px 7px 36px;
  }
}
