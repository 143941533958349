.btn-drop-checkbox {
    position: relative;
    z-index: 1;

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__drop-box {
        display: flex;
        flex-direction: column;
        width: 210px;
        padding: 0px;
        align-items: flex-end;
        border-radius: 8px;
        border: 2px solid #EDEFED;
        background: #FFF;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
    }

    &__drop-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        width: 100%;
        background-color: inherit;
        border: none;
        cursor: pointer;
        color: #020617;
        text-overflow: ellipsis;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        padding: 16px 20px;
        text-align: left;
        border-bottom: 2px solid #EDEFED;

        &:last-child {
            border: none;
        }

        &:hover {
            transition: 0.5s;
            background: #FAFBFA;
        }
    }
}