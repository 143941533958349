.modal-task-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 1300px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-radius: 8px;
    height: 80vh;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        width: 100%;
        padding: 24px;
        border-bottom: 1px solid #EDEFED;
    }

    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 1; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
        word-break: break-word;;
        color: #020617;
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        min-height: 36px;

        span {
            text-transform: uppercase;
            color: #51CA64;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        height: calc(100% - 85px);
    }

    &__sidebar {
        width: 30%;
        overflow: auto;

        ul {
            display: flex;
            flex-direction: column;

            li {
                width: 100%;

                .btn-point,
                .btn-point_active {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #44484F;
                    text-overflow: ellipsis;
                    font-family: NunitoSans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    width: 100%;
                    padding: 14px 24px;
                    border: none;
                    border-bottom: 1px solid#EDEFED;
                    background-color: #fff;
                    text-transform: uppercase;

                    &_active,
                    &:hover {
                        transform: 0.5s;
                        color: #51CA64;
                        background: #F0FDF4;
                    }
                }
            }
        }

    }

    &__main {
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-left: 1px solid #EDEFED;
        width: 70%;
        height: 100%;
        padding: 24px;
        height: 100%;
    }

    &__main-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        height: 100%;
        align-items: flex-start;
        overflow: auto;
        padding-top: 8px;
    }

    &__select-group {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        width: 100%;

        .input-dinamyc-placeholder {
            max-width: 210px;
        }
    }

    &__btn-box {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
    }
}