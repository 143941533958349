@import 'src/common/styles/svg_img';

.description-improvement-landing {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    gap: 24px;

    .toolbar-top {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        width: 100%;
        align-items: center;

        .filters-container{
            width: 85%;
            align-items: center;
        }
    }
}

.auto-rich-landing {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 24px;
    display: flex;
    gap: 20px;
    justify-content: center;
    border-radius: 16px;
    padding: 30px;


    & .close_btn {
        display: block;
        background-image: url($cross);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: .45em;
        height: .45em;
        position: absolute;
        right: .35em;
        top: .35em;
    }


    .rich {
        &__img {
            display: inline-block;
            max-width: 480px;
            height: auto;
            width: 100%;

            img {
                display: inline-block;
                width: 100%;
            }
        }

        .title {
            padding: 0;
        }

        &__information-box {
            display: flex;
            max-width: 500px;
            flex-direction: column;

            .title {
                padding-left: 0;
            }
        }

        &__group-btn {
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            display: flex;
            gap: 16px;
            margin-top: 29px;
        }

        &__btn_blue,
        &__btn_green {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 234px;
            height: 56px;
            background-color: #1890FF;
            border-radius: 16px;
            cursor: pointer;
            position: relative;

            

        }

        .tooltip {
            z-index: 1;
            width: 200px;
            left: 18px;
            border: 1px solid green;
            border-radius: 10px;
            padding: 8px;
            position: absolute;
            border: 1px solid#C6C6C6;
            background-color: #fff;
            top: 33px;
            display: none;

            & .text {
                color: #020617;
                font-size: 12px;
                margin: 0;
            }

            &::after,
            &::before {
                content: '';
                position: absolute;
                background: rgb(255, 255, 255);
                top: 20px;
                left: 50%;
                top: -7px;
                width: 10px;
                height: 10px;
                border-top: 1px solid #C6C6C6;
                border-left: 1px solid#C6C6C6;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }

            &::after {
                z-index: -10;
                box-shadow: none;
            }
        }

        &__btn_green {
            background-color: #27BC62;
        }

        &__btn-content {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;

        }

        &__text-big {
            font-size: 20px;
            color: white;
        }

        &__text-small {
            font-size: 14px;
            color: white;
        }

        &__list-item {
            margin-top: 40px;

            .landing__list {
                display: flex;
                flex-direction: column;
                margin-top: 25px;
                gap: 25px;
            }
        }
    }
}



@media (max-width: 1440px) {
    .auto-rich-landing {
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

.rich-table__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
    border-radius: 16px;
    overflow: hidden;

    .table-box {
        width: 100%;
        margin-top: 0;
        max-height: auto;
    }
}

.rich__group-btn {
    display: flex;
    width: 100%;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1064px;
    align-items: center;

    .round__group-blue {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .btn__bej {
            margin-top: 0;
            width: 200px;
            max-height: 32px;
            -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            transition: all 0.3s;
        }
    }

    .round__group-btn {
        display: flex;
        gap: 16px;
        justify-content: center;
        width: 200px;

        .btn__doc,
        .btn__doc-add {
            border: none;
            width: 32px;
            height: 32px;
            background-color: inherit;
            cursor: pointer;
            border: none;
            background-repeat: no-repeat;
            background-position: center;
        }

        .btn__doc {
            background-image: url($doc);
        }

        .btn__doc-add {
            background-image: url($doc_add);
        }

    }
}

.text__blue {
    color: #1890FF;
}

.rich__group-btn  + .rich-table__content .table-box {
    height: 100%;
    max-height: 100%;
}

.child-content {
    display: flex;
    column-gap: 12px;
    row-gap: 8px;
    align-items: center;
    flex-wrap: wrap;
}