@import 'src/common/styles/svg_img.scss';
.text_grey {
    color: #888;
    font-size: 12px;
}

.analysis-category {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .title_main {
        color: var(--slate-950, #020617);
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
    }

    .text_grey {
        color: #888;
        font-size: 12px;
    }

    .chart-and-metrics {
        display: flex;
        width: 100%;
        gap: 12px;

        .metrics-items {
            display: flex;
            width: fit-content;
            gap: 8px;
            flex-wrap: wrap;
            border-radius: 8px;
            max-width: calc(216px*2 + 12px);
        }
    }

    .range-group-btn-and-range-box {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-left: auto;

        .selector_wrapper {
            max-width: 124px;
            min-width: 124px;
        }

        .drop-box {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .tooltip-blue {
        margin-top: 12px;
    }

    .toolbar-top {
        display: flex;
        width: 100%;
        gap: 16px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .checkbox-container {
            display: flex;
            gap: 8px;
            align-items: center;

            .text_label,
            .text_label_disabled {
                color: #020617;
                font-family: Rubik;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;

                &_disabled {
                    color: #D3DEE7;
                }
            }
        }

        .show-breadcrumbs-btn {
            max-width: 250px;
        }

        .breadcrumbs-container {
            display: flex;
            flex-direction: row;
            width: calc(100% - 340px);
            align-self: center;
            gap: 30px;
        }

        .filter-group {
            display: flex;
            align-items: end;
            flex-wrap: wrap;
            gap: 12px;
        }
    }

    .toolbar-medium {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    .tab-box {
        display: flex;
        width: auto;
        row-gap: 12px;
        flex-wrap: wrap;

        .btn__tab,
        .btn__tab_active {
            cursor: pointer;
            height: 34px;
            min-width: 130px;
            width: auto;
            font-size: 16px;
            font-weight: 500;
            background-color: inherit;
            border: none;
            padding-right: 20px;
            padding-left: 20px;
            white-space: nowrap;
            line-height: 20px;
            border-bottom: 2px solid #E0E7F2;

            &:hover {
                transition: 0.5s;
                border-bottom: 2px solid #1890FF;
                color: #1890FF;
            }

            &_active {
                border-bottom: 2px solid #1890FF;
                color: #1890FF;
            }
        }
    }

    .table-box-component {
        align-items: flex-start;
    }

    .pie-chart {
        margin-top: 16px;
    }

}

.analysis-category {

    .charts-and-metrics,
    .charts-and-metrics_hide {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
        justify-content: space-between;
        background-color: white;
        background-color: white;
        border-radius: 8px;
        padding: 20px;
        padding-right: 88px;
        margin-top: 12px;
        position: relative;
        height: -webkit-fill-available;
        min-height: calc(24px + 40px);
        max-height: 100%;
        overflow: hidden;
        transition: max-height 1s;

        .right-content {
            display: flex;
            flex-direction: column;
            gap: 28px;
            max-width: 506px;
            width: 100%;
            flex-shrink: 0;
            flex-grow: 0;
        }

        .title {
            color: #888888;
            font-size: 16px;
            margin-top: 4px;
        }

        .inside-content {
            display: flex;
            width: 100%;
            overflow: hidden;
            gap: 40px;
            // flex-wrap: wrap;

            .metrics-box {
                display: flex;
                align-items: start;
                height: min-content;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 15px;
                max-width: calc(248px*2 + 10px);
                width: 100%;
                min-height: 30px;
            }

            .statistic {
                display: flex;
                align-items: start;
                height: min-content;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 15px;
                max-width: calc(248px*2 + 10px);
                width: 100%;
                min-height: 30px;

                &__item {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    min-width: 248px;
                    padding-left: 12px;
                    padding-right: 12px;

                    .text_grey {
                        font-size: 14px;
                    }

                    .text {
                        font-weight: 500;
                        font-size: 16px;
                    }

                    .text_burgundy {
                        font-weight: 500;
                        color: #A53100;
                        font-size: 16px;
                    }
                }
            }
        }

        .line_chart {
            flex-shrink: 1;
            padding: 0;
            width: 100%;
            max-height: 250px;
            min-height: 250px;
        }

        .chart-box {
            // width: auto;
            // flex-shrink: 1;
            // flex-grow: 1;
            // opacity: 1;
            // visibility: visible;
            // transition: 0.5s;
            // overflow: hidden;
            min-width: 100%;
            // max-width: calc(100% - 506px - 40px);

            // .line_chart {
            //     flex-shrink: 1;
            //     padding: 0;
            //     width: 100%;
            //     max-height: 250px;
            //     min-height: 250px;
            // }
        }

        &_hide {
            max-height: 72px;
            height: 100%;
        }
    }

    .table-box-component {
        //  width: min-content;
        align-items: flex-start;
    }
}

@media (max-width: 1440px) {
    .analysis-category {

        .charts-and-metrics,
        .charts-and-metrics_hide {
            .chart-box {
                max-width: 100%;
            }

            .right-content {
                width: 100%;
                max-width: 100%;
            }

            .inside-content {
                flex-wrap: wrap;

                .metrics-box {
                    max-width: 100%;
                }
            }
        }
    }

}