.landing-integration {

    .landing-performance___block_long .landing__list {
        gap: 33px;
    }

    .landing__list {
        margin-top: 36px;
    }

    .bkg-grey-dark {
        display: flex;
        justify-content: center;
        align-items: center;

        .play {
            margin-top: 0;
        }
    }

    .logo-group__img {
        margin-top: 43px;
    }

    .landing__block_small {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;

        .landing__header-box {
            padding: 0;
            align-items: center;
            gap: 24px;
        }

        .btn__green {
            margin-top: 0;
            height: 56px;
            width: 360px;
            max-width: 360px;
            border-radius: 16px;
            font-size: 24px;
        }
    }
}