@import url('./common/styles/buttons.scss');
@import url('./common/styles/modal.scss');
@import url('./common/styles/DatePicker.scss');
@import url('./common/styles/breadcrumbs.scss');
@import url('./font.scss');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter';
}

button {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

li {
    list-style: none;
}

body {
    overflow: visible;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #F9FAFB;
    scroll-behavior: smooth;
}

// основной контейнер

.global-search-input {
    z-index: 1;
}

.main-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    padding: 20px 20px 150px;
}

.main {
    display: flex;
    width: 100%;
    height: auto;
}

.content {
    display: flex;
    flex-shrink: 1;
    width: 100%;
    min-height: calc(100vh);
    flex-direction: column;
    background-color: #F6F7F6;
    position: relative;
}

#root {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // overflow: hidden;
}

// скролл

* {

    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        border-radius: 24px;
        //  background-color: #ffffff;
    }


    &::-webkit-scrollbar-track-piece {
        background-color: #ffffff;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        //   background-color: #b01a1a;
        //  border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb:vertical {
        width: 11px;
        height: 12px;
        background-color: #636D65;
        border-radius: 10px;
        scrollbar-width: none;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        height: 10px;
        width: 12px;
        background-color: #636D65;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-button:single-button:vertical:decrement {
        background-color: rgb(255 255 255 / 0%);
        // background-color: #fff;
        // border-radius: 50%;
        padding: 4px;
        height: 15px;
        width: 100%;
        // border-radius: 0px;
        background-image: url('./pages/TaskList/img/Up.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        // clip-path: polygon(50% 0%, 0% 100%, 100% 100%)
    }

    &::-webkit-scrollbar-button:single-button:vertical:increment {
        height: 15px;
        width: 100%;
        background-color: rgb(255 255 255 / 0%);
        border-radius: 0px;
        background-image: url('./pages/TaskList/img/Down.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%)
    }

    &::-webkit-scrollbar-button:single-button:horizontal:decrement {
        height: 100%;
        width: 15px;
        background-color: rgb(255 255 255 / 0%);
        border-radius: 0px;
        background-image: url('./pages/TaskList/img/Left.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &::-webkit-scrollbar-button:single-button:horizontal:increment {
        height: 100%;
        width: 15px;
        background-color: rgb(255 255 255 / 0%);
        border-radius: 0px;
        background-image: url('./pages/TaskList/img/Right.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.loader_wrapper {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
}

.b24-form {
    position: absolute;
    right: 2em;
    bottom: 1em;
}