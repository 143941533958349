.add-product-ver3 {
    display: flex;
    flex-direction: column;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    height: 100%;
    gap: 24px;

    .product-card {
        display: flex;
        gap: 32px;
        display: flex;
        min-width: 1057px;
        width: 100%;
        gap: 18px;
        border-radius: 8px;
        position: relative;
    }
    
    &__header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 32px;
        align-self: start;

        .btn-group {
            display: flex;
            gap: 12px;
        }

        .title {
            color: #020617;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .breadcrumb-link {
            display: flex;
            gap: 8px;
            align-items: center;
            cursor: pointer;
            color: var(--text-teritary-black, #8F99A3);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            &::before {
                content: '';
                width: 24px;
                height: 24px;
                background-image: url('./img/arrow_prev.svg');
                background-size: 24px;
                background-repeat: no-repeat;
                background-position: center;

            }
        }
    }

    &__content {
        display: flex;
        width: 100%;
        gap: 32px;
        min-height: 600px;

        .context-more {
            position: absolute;
            top: 24px;
            right: 24px;
            z-index: 5;

            .btn-more {
                width: 24px;
                min-width: 24px;
                height: 24px;
                background-image: url('./img/more-vertical.svg');
                border: none;
                background-color: inherit;
            }

            .drop-menu,
            .drop-menu_active {
                position: absolute;
                top: calc(100% + 10px);
                width: 124px;
                height: 64px;
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                border: 1px solid #E0E7F2;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(30, 41, 59, 0.06);
                overflow: hidden;
                opacity: 0;
                visibility: hidden;
                transition: 0.3s;

                &_active {
                    opacity: 1;
                    visibility: visible;
                    transition: 0.3s;
                }

                &__btn {
                    padding-left: 8px;
                    padding-right: 8px;
                    width: 100%;
                    height: 50%;
                    background-color: inherit;
                    border: none;
                    text-align: left;
                    color: var(--text-primary-black, #1E293B);
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &:hover {
                        transition: 0.3s;
                        background-color: #F7FAFD;
                    }
                }
            }
        }




        .side-panel {
            display: flex;
            flex-direction: column;
            min-width: 300px;
            max-width: 300px;
            height: 100%;
            gap: 12px;
            margin-top: 40px;

            &__img-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                min-height: 226px;
                border-radius: 8px;
                border: 2px solid #EDEFFB;
                background: #fff;
                overflow: hidden;

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .not-img {

                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: flex-start;
                gap: 8px;
                flex-shrink: 0;
                border-radius: var(--Base-radius, 8px);
                background: #FFF;
            }

            .info-item {
                display: flex;
                width: 100%;
                padding: 16px;
                flex-direction: column;
                gap: var(--4, 4px);
                border-radius: 8px;
                background: #F7FAFD;

                .title {
                    color: #2F506D;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                }

                .text {
                    color: #6B8CA9;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.06px;

                    .link-blue {
                        color: #1890FF;
                        text-decoration: underline;
                    }
                }
            }

            .aside-notification {
                display: flex;
                width: 202px;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
                border-radius: 8px;
                background: #F7FAFD;

                .title {
                    color: #2F506D;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    /* 114.286% */
                }

                .text {
                    color: #6B8CA9;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    white-space: pre-wrap;
                    /* 114.286% */
                }
            }

            .err {
                background-color: #FEE2E2;

                .title {
                    color: #EF4444;
                }

                .text {
                    color: #F87171;
                }
            }

            .warning {
                background-color: #FFEDD7;

                .title {
                    color: Orange;
                }

                .text {
                    color: #FB923C;
                }
            }

            .advice {
                background-color: #CFE7FE;

                .title {
                    color: #0161B9;
                }

                .text {
                    color: Текст;
                }
            }
        }
    }
}