.space-page {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;

    &__title {
        color: #0C2037;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;
        justify-content: space-between;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        width: 100%;
    }
}

.card-space {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 240px;
    border-radius: 12px;
    border: 1px solid #EDEFED;
    cursor: pointer;
    
    background: #FFF;

    &__header {
        display: flex;
        gap: 8px;
        align-items: self-start;
        width: 100%;
        padding: 8px 12px;
        border-bottom: 1px solid #EDEFED;

    }

    &__title {
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        min-height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 2; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
    }

    &__content {
        display: flex;
        width: 100%;
        padding: 20px 12px;

        .sum_executor {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #F1F5F9;
            color: #2E3552;
            text-align: center;
            font-family: NunitoSans;
            font-size: 10px;
            font-style: normal;
            font-weight: 800;
            line-height: 8px;
            margin-left: 8px;
            cursor: pointer;

            span {
                color: rgba(46, 53, 82, 0.60);
            }
        }

        .tooltip-container {
            margin-left: -6px;

            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }
}