
.tools {
    &__content {
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 18px;
        position: relative;

        
        .title-box {
            cursor: pointer;
            max-width: 280px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-right: 32px;
            gap: 8px;

            .title {
                padding-left: 24px;
            }
        }
       
    }

    &__link-box {
        display: flex;
        gap: 16px;
        align-items: center;
        min-height: 48px;
        background-color: #E6F7FF;
        border-radius: 16px;
        width: 100%;
        padding: 16px 22px;
        padding-right: 8px;

        .text {
            font-size: 14px;
            white-space: nowrap;
        }
    
    }

    .title {
        padding-left: 0;
    }

    &__info-box {
        display: block;
        min-height: 48px;
        background-color: #FFF7AC;
        border-radius: 16px;
        width: 100%;
        padding: 16px 22px;
        padding-right: 8px;
    }

    &__header {
        display: flex;
        width: 100%;
        gap: 24px;
        align-items: center;
    }

    &__select-box {
        display: flex;
        gap: 16px;
        justify-content: center;
       
        .selector_wrapper  {
            height: 32px;
            max-width: 100%;
            width: 100%;
            min-width: 150px;
           
            .dropdown_selector, .css-13cymwt-control {
                min-height: 32px;
                height: 32px;
            }

            .selector_title {
                font-size: 14px;
                width: 100px;
            }

        }
    }
}

@media (max-width: 1500px) {
    .tools__select-box {
        flex-wrap: wrap;
       
        .selector_wrapper  {
            max-width: 350px;
    }
}
}

.add-links-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 160px;
    width: 160px;
    height: auto;

    &__group-links {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__group-btn {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}



.btn__white {
    width: 100%;
    min-height: 48px;
    background-color: #fff;
    color: #1890FF;
    border: 1px solid #D9D9D9;
    border-radius: 16px;
    cursor: pointer;
}

.title-and-nav-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: inherit;
    max-width: 240px;

    .title {
        display: inline-block;
        width: 100%;
        padding-left: 24px;
    }
}