.modal-exit-confirmations {
    width: 100%;
    max-width: 460px;
    min-width: 360px;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 20px 50px 0px rgba(30, 41, 59, 0.10);

    img {
        width: 106px;
        width: 100px;
    }

    .btn-group {
        display: flex;
        gap: 12px;
        width: 100%;


        .btn__white-blue,
        .btn__red {
            width: 100%;
            min-height: 56px;
            max-width: 100%;
        }
    }


}