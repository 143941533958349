.modal-successful-product-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 560px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        color: #020617;
        text-align: center;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
    }

    .text_description {
        color: #020617;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;

        .link_blue {
            @extend .text_description;
            cursor: pointer;
            color: #1890FF;
        }
    }

    .img {
        width: 70px;
        height: 70px;
    }

    .btn-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 12px;
        align-items: center;
    }
}