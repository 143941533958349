.work-with-reviews {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: calc(1440px - 40px);
        margin-left: auto;
        margin-right: auto;
    }

    .btn-ozon {
        display: flex;
        width: auto;
        gap: 8px;
        height: 40px;
        align-items: center;
        border-radius: 8px;
        border: 2px solid #4285F4;
        background: #fff;
        color: #0B110B;
        text-align: center;
        font-family: "Nunito Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; 
        padding-left: 8px;
        padding-right: 8px;

        &:hover {
            transition: 0.5s;
            background-color: #D1E1F8;
        }
    }

    .table-box-component, .table-box-component_no-data {
        position: relative;
    }

    // для таблицы
    .table-box-component__content,
    .table-box-component_no-data__content {
        max-height: 100%;
    }


    .title-and-view {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: space-between;
        margin-bottom: 12px;
        flex-wrap: wrap;
    }

    .title_main {
        color: var(--slate-950, #020617);
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 114.286% */
        letter-spacing: -0.56px;
       // margin-bottom: 12px;
        display: flex;
        align-items: center;

        .counter{
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            margin-left: .5em;
            background-color: #fff;
            border-radius: 1em;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            color: #101810;
            font-family: NunitoSans;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.8px;
            padding: 0 12px;
            height: 44px;

            &:hover {
                transition: 0.5s;
                background-color: #E5E7EB;
            }
        }
    }

    .toolbar-top,
    .toolbar-medium {
        display: flex;
        gap: 12px;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;

        .tabs-and-rating {
            display: flex;
            align-items: center;
            gap: 12px;

        }
    }

    .filters-container{
        width: 90%;
    }

    .tab_view,
    .tab_view_disabled {
        overflow: hidden;
        margin-left: auto;
        min-width: 80px;
        max-width: 80px;
        display: flex;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #EDEFED;
        background: #FFF;

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
            cursor: pointer;

        }

        .table-view:hover,
        .table-view_active,
        .chat-view:hover,
        .chat-view_active {
            background-color: #E1E6E2;
            transition: 0.3s;

            svg {
                path {
                    fill: #51CA64;
                    //  stroke: #1890FF;
                }
            }
        }

        // .chat-view,
        .chat-view:hover,
        .chat-view_active {
            background-color: #E1E6E2;

            svg {
                path {
                    stroke: #51CA64;
                    fill: #E1E6E2;
                }
            }
        }

        &__item:nth-child(2) {
            border-left: 1px solid #EDEFED;
        }

        &_disabled {
            background-color: #F8F9FE;
            cursor: not-allowed;

            .table-view,
            .chat-view,
            .table-view:hover,
            .table-view_active,
            .chat-view:hover,
            .chat-view_active {
                cursor: not-allowed;
                background-color: #F8F9FE;
                transition: 0.3s;

                svg {
                    path {
                        fill: #D3DEE7;
                    }
                }
            }

            .chat-view,
            .chat-view:hover,
            .chat-view_active {
                svg {
                    path {
                        stroke: #D3DEE7;
                        fill: #F8F9FE;
                    }
                }
            }
        }
    }

    .filter-group {
        display: flex;
        gap: 8px;

        .btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 32px;
            width: 200px;
            border-radius: 4px;
            border: 1px solid var(--stroke-primary-primary, #E0E7F2);
            background-color: white;
            color: var(--all-text-secondary-text, #475569);
            padding-left: 8px;
            padding-right: 8px;
            color: var(--all-text-secondary-text, #475569);
            text-align: left;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            cursor: pointer;

            &:hover {
                background-color: #E0E7F2;
                transition: 0.3s;
            }
        }

    }
}

.rating-option {
    display: inline-flex;
    align-items: center;
    gap: 5px;

    .text_span {
        color: #020617;
        font-family: Rubik;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
}