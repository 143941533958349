.modal-add-links {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    justify-content: space-between;
    background-color: #F5F5F5;
    width: 100%;
    min-height: 240px;
    border-radius: 16px;
    position: relative;
    max-width: 640px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.2);

    .modal__group-btn {
        right: 24px;
        top: 24px;
    }

    .title {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
    }

    .text {
        text-align: center;
        margin-top: 32px;
        font-size: 20px;
    }

    .btn__blue-modal {
        cursor: pointer;
        width: 100%;
        height: 56px;
        background-color: #E6F7FF;
        border-radius: 0 0 16px 16px;
        border: none;
        height: 56px;
        color: #1890FF;
        font-size: 16px;
    }
}

.icon_done-round {
    display: inline-block;
    width: 64px;
    height: 64px;
    border: 4px solid white;
    background-repeat: no-repeat;
    border-radius: 50%;
    position: absolute;
    top: -28px;
    left: calc(50% - 32px);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(28, 138, 219, 0.5);
    -moz-box-shadow: 0px 0px 20px 0px rgba(28, 138, 219, 0.5);
    box-shadow: 0px 0px 20px 0px rgba(28, 138, 219, 0.5);
}