.modal-planning-post {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 970px;
    width: 100%;
    min-width: 360px;
    background-color: white;
    border-radius: 8px 0px 0px 8px;
    height: 100%;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        padding: 16px 32px;
        border-bottom: 2px solid var(--Stroke-Primary, #EDEFED);

        .title {
            color: #020617;
            font-family: Rubik;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.56px;
        }

        .text_date-create {
            color: #64748B;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 32px 24px;
        gap: 24px;
        overflow: auto;

        .toolbar {
            display: flex;
            gap: 12px;

            .single-date-picker {
                border: 2px solid #E0E7F2;
                border-radius: 8px;
                min-width: 210px;
                max-width: 210px;
            }
        }
    }

    .btn-close {
        border: none;
        background-color: inherit;
        position: absolute;
        top: 24px;
        right: 24px;
    }

    .opton-planing-post {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #0C2037;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
    }

    .icon-group {
        display: flex;
        align-items: center;

        .icon {
            width: 24px;
            min-width: 24px;
            height: 24px;
            border-radius: 50px;
            margin-left: -8px;

            &:nth-child(1) {
                margin-left: 0px;
            }
        }
    }

    .title {
        color: #0C2037;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
    }

    &__content {
        display: flex;
        align-self: start;
        width: 100%;
        gap: 12px;
        height: 100%;

        .textarea-and-drop-zone {
            display: flex;
            gap: 24px;
            height: 100%;

            .textarea-dynamic-placeholder {
                height: 100%;

            &__content {
                    min-height: 100%;
                    height: 100%;
                }
            }

            
        }

        .left-content,
        .right-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 14px;

            .text_grey {
                color: #64748B;
                font-family: Rubik;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
            }

            .photo-content, .video-content {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                gap: 14px;
            }

            .selector_wrapper {
                max-width: 100%;
            }

            .text {
                color: #0C2037;
                font-family: Rubik;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }

        .right-content {
            width: 100%;
            max-width: 340px;
        }
    }

    .selected-date {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .checbox-content {
            display: flex;
            align-items: center;
            gap: 4px;
            height: 18px;

            .text {
                color: #697A8F;
                font-family: Rubik;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }


    .date-picker {
        display: flex;
        background: white;
        border-radius: 16px;
        justify-content: space-around;
        align-items: center;
        height: 32px;
        padding-right: 16px;
        cursor: pointer;
    }

    .date-picker input {
        background: transparent;
        border: none;
        cursor: pointer;
        text-align: center;
    }

    .date-picker input:focus {
        border: none;
        outline: none;
    }

    .first-date_picker {
        text-align: end;
    }


    .toolbar-group {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 12px;

        .date-picker {
            border: 1px solid #E7E6E9;
            border-radius: 8px;
            max-width: 200px;
            width: 100%;
            min-height: 40px;
        }
    }

    .group-btn {
        display: flex;
        gap: 12px;
        width: 100%;
    }
}