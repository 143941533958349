.automation-responses {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: calc(1440px - 120px);
    margin-left: auto;
    margin-right: auto;
    gap: 24px;

    .tab-sort {
        overflow: hidden;
        width: auto;
        display: flex;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #EDEFFB;
        margin-left: auto;

        .btn,
        .btn_active {
            display: flex;
            align-items: center;
            gap: 6px;
            justify-content: center;
            height: 100%;
            cursor: pointer;
            border-left: 1px solid var(--table-border, #EDEFFB);
            border: none;
            background-color: #fff;
            padding-left: 16px;
            padding-right: 16px;
            color: var(--Slate-950, #020617);
            font-family: Rubik;
            font-size: 13px;
            font-style: normal;
            font-weight: 300;
            line-height: 14px;
            border-left: 1px solid #EDEFFB;

            &:hover,
            &_active {
                transition: 0.5s;
                background: #51CA64;
                color: white;

                path {
                    fill: #fff
                }
            }

            &:nth-child(1) {
                border: none;
            }

            .arrow,
            .arrow_up {
                min-width: 16px;
                width: 16px;
                height: 16px;

                &_up {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .info-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        gap: 12px;
        padding: 36px 12px;

        .title {
            color: #0F172A;
            text-align: center;
            font-family: Rubik;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: -0.2px;
        }

        .text {
            color: #68727D;
            text-align: center;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            max-width: 60%;
        }

        &__img {
            max-width: 280px;
            max-height: 280px;
            width: 100%;
            height: auto;
        }
    }

    .title_main {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    .toolbar-medium {
        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: space-between;
    }

    .chat {
        max-height: calc(100% - 102px);
    }

    .chat__header,
    .toolbar-panel {
        flex-direction: row;
        background-color: #FAFBFA;
    }

    .chat-review {
        display: flex;
        width: 100%;
        height: 100%;
        max-height: 730px;
        min-height: 730px;
        border: 1px solid #EDEFED;
        border-radius: 8px;
        overflow: hidden;

        &__toolbar-top {
            display: flex;
            align-items: center;
            gap: 12px;
            width: 100%;
            border-bottom: 1px solid #EDEFED;
            background-color: #fff;

            .btn {
                min-width: 200px;
                margin-right: 16px;
                display: flex;
                height: 32px;
                padding: 4px 12px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                background: #FAFBFA;
                border-radius: 8px;
                border: 1px solid #EDEFED;
                overflow: hidden;
                color: #555C9E;
                text-overflow: ellipsis;
                font-family: Rubik;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        &__toolbar-medium {
            display: flex;
            min-height: 61px;
            justify-content: space-between;
            gap: 24px;
            width: 100%;
            padding: 10px 16px;
            background-color: #fff;
            border-bottom: 1px solid #EDEFED;
        }


        &__left-content {
            width: 45%;
            height: 100%;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #EDEFED;



            .template-content {
                width: 100%;
                height: 100%;
                overflow: auto;
                background-color: #fff;
                max-height: calc(100% - 102px);

                // Контент если нет шаблонов
                .no-template {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 24px;

                    .info-block {
                        display: flex;
                        flex-direction: column;
                        padding: 24px;
                        width: 100%;
                        gap: 12px;

                        .title {
                            color: #0F172A;
                            text-align: center;
                            font-family: Rubik;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 30px;
                            letter-spacing: -0.2px;
                        }

                        .text {
                            color: #68727D;
                            text-align: center;
                            font-family: Rubik;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            max-width: 70%;
                            margin-left: auto;
                            margin-right: auto;
                        }


                    }

                    .banner_template {
                        max-width: 218px;
                        width: 100%;
                    }
                }

            }
        }

        &__right-content {
            min-width: 870px;

            .chat {

                &__header {
                    display: flex;
                    gap: 24px;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    min-height: 80px;
                    max-height: 80px;

                    .title {
                        display: -webkit-box;
                        -webkit-line-clamp: 2; // количество строк
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        hyphens: auto;
                        overflow: hidden;
                        color: #020617;
                        text-overflow: ellipsis;
                        font-family: NunitoSans;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    // Контейнер с кнопкой Добавить

    .btn-container-and-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 12px;
        height: 100%;

        .btn-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 12px;
            width: calc(50% + 20px);
            margin-right: auto;

            .tooltip-container {
                margin-left: auto;
            }
        }

        .btn-bulk-edit {
            display: flex;
            align-items: center;
            gap: 8px;
            min-height: 40px;
            width: auto;
            background-color: inherit;
            border-radius: 8px;
            padding-left: 8px;
            padding-right: 8px;
            border: 2px solid #E4E5F9;
            color: #555C9E;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        .btn-add {
            display: flex;
            width: 40px;
            height: 40px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: #E4E5F9;
            border: none;
        }
    }

}

// Шаблон-сообщения 

.template-list {
    display: flex;
    flex-direction: column;
    padding: 8px 24px;

    .template-gropp-item,
    .template-gropp-item_active {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 24px;
        gap: 24px;
        border-radius: 10px;
        border-bottom: 2px solid #EDEFFB;
        cursor: pointer;

        &_active {
            background-color: #DCFCE7;
        }

        &__bottom-info {
            display: flex;
            gap: 12px;
            width: 100%;
            justify-content: space-between;
        }

        input {
            border: none;
            color: #020617;
            text-overflow: ellipsis;
            font-family: NunitoSans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            &::placeholder {
                color: #64748B;
            }

            &:focus {
                outline: none;
                border: none;
            }
        }

        .info-box {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: calc(100% - 70px - 12px);

            .title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-family: NunitoSans;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }

            .template_count {
                display: flex;
                gap: 8px;
                align-items: center;

                .text {
                    overflow: hidden;
                    white-space: nowrap;
                    color: #4750A6;
                    text-overflow: ellipsis;
                    font-family: Rubik;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                    letter-spacing: -0.22px;
                }
            }
        }

        .btn-group {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 11px;
            border-radius: 8px;
            background: #F8F9FE;

            .btn-edit,
            .btn-delete {
                border: none;
                background-color: inherit;
                width: 18px;
                height: 18px;
                min-width: 18px;
            }
        }
    }
}

//Шаблон сообщения

.template-message {
    display: flex;
    width: 100%;
    gap: 12px;
    align-items: flex-start;

    &__item {
        display: flex;
        width: 100%;
        gap: 12px;
        align-items: flex-start;
    }

    &__tags-list {
        display: flex;
        gap: 4px;
    }

    &__tag {
        display: flex;
        padding: 2px 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        background: #FFF;
        color: #64748B;
        text-align: right;
        font-family: Rubik;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
    }

    &__bottom-info {
        display: flex;
        width: 100%;
        gap: 24px;
        justify-content: space-between;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-radius: 10px 2px 10px 10px;
        align-items: end;
        padding: 12px 24px;
        width: 100%;
        background-color: #FBECDF;

        .text {
            width: 100%;
            color: #090C2E;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }

        .text_date {
            color: #64748B;
            font-family: Rubik;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
        }
    }

    .btn-group {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 11px;
        border-radius: 8px;
        background: #F8F9FE;

        .btn-edit,
        .btn-delete,
        .btn-add {
            border: none;
            background-color: inherit;
            width: 18px;
            height: 18px;
            min-width: 18px;
        }
    }
}
