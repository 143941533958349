@import '../../common/styles/mixins.scss';
@import '../../common/styles/svg_img.scss';

.autorization {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(560px + 96px);
    gap: 24px;
    width: 100%;
    height: auto;
    background-color: #F9FAFB;
    padding-left: 48px;
    padding-right: 48px;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 32px;

    &__input-field {
        width: 100%;
        height: 100%;
        font-family: 'Inter';
        font-size: 14px;
        padding: 16px 20px;
        margin-top: 20px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;

        &:focus {
            outline: none;
            border: 1px solid #1890FF;
            -webkit-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
            -moz-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
            box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
        }

        &::placeholder {
            font-size: 14px;
            font-family: "Inter";
            color: rgb(179, 179, 179);
        }

        &_err {
            display: block;
            width: 100%;
            height: 100%;
            font-family: 'Inter';
            font-size: 14px;
            padding: 16px 20px;
            margin-top: 32px;
            border: 1px solid #F0F0F0;
            border-radius: 16px;
            border: 1px solid #FF4D4F;
            position: relative;
        }
    }


    .btn__blue {
        min-width: 100%;
        max-width: 100%;
        min-height: 40px;
        font-size: 14px;
        border-radius: 8px;
        margin-top: 32px;
    }

    &__footer {
        width: 100%;
        max-width: 1440px;
        padding: 40px 48px;
        background-color: inherit;
        height: auto;
        margin-top: auto;

    }



    .bottom-box {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        width: 100%;

        .text_blue {
            font-size: 14px;
            сursor: pointer;
        }
    }
}

.input-with-btn {
    width: 100%;
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    display: flex;

    .input-box {
        width: 100%;

        .autorization__input-field {
            width: 100%;
            height: 100%;
            font-family: 'Inter';
            font-size: 14px;
            padding: 0px;
            margin-top: 24px;
            border-radius: 16px;
            border-radius: 0px;
            border: none;
            background: none;
        }
    }

    &_active {
        width: 100%;
        display: flex;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;

        &::after {
            content: "Данный логин уже зарегистрирован в системе";
            z-index: 100;
            display: inline-block;
            position: absolute;
            width: 100%;
            bottom: -26px;
            color: #FF4D4F;
            height: 16px;
            font-family: "Inter";
            font-size: 12px;
        }
    }


}


.checkbox {

    &__box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        label {
            display: flex;
            align-items: center;
        }

        & .text_red {
            margin-top: 0;
            color: #FF4D4F;
        }

        & .text {
            margin-left: 12px;
        }

    }


}

.text {
    font-size: 14px;
    font-family: 'Inter';

    &_blue {
        display: inline-block;
        text-decoration: underline;
        color: #1890FF;

    }

    &_red {
        color: #FF4D4F;
        margin-top: 24px;
    }
}

.title {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 500;
}

.input-with-btn {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
}

// .hide,
// .show {
//     cursor: pointer;
//     position: absolute;
//     display: block;
//     width: 16px;
//     height: 16px;
//     bottom: 18px;
//     right: 14px;
//     @include optimizeSafariSvg(url('./img/hide.svg'));
//     background-color: unset;
//     border: none;
// }

// .show {
//     @include optimizeSafariSvg(url('./img/show.svg'));
// }

.check-your-mail__box {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.checkbox__box_registration {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    label {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}