.modal-adding-ready-scenario {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2000;
    display: flex;
    max-width: 1500px;
    width: calc(100%) ;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    height: calc(100vh - 40px);
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__title {
        white-space: nowrap;
        color: #020617;
        font-family: NunitoSans;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
    }

    &__toolbar-top {
        display: flex;
        gap: 24px;
        align-items: flex-start;

        .textarea-dynamic-placeholder__content {
            min-height: 92px;
        }

        .filters-container {
            max-width: 432px;
            min-width: 432px;
        }
    }

    &__group-btn {
        display: flex;
        gap: 12px;
    }

    &__label {
        display: flex;
        gap: 8px;
        align-items: center;

        span {
            white-space: nowrap;
            color: #475569;
            font-family: NunitoSans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    &__header {
        display: flex;
        gap: 60px;
        width: 100%;
        justify-content: space-between;
        border-radius: 8px 8px 0px 0px;
        padding: 32px 24px 0px;

        .input-dinamyc-placeholder {
            max-width: 345px;
            margin-right: auto;
        }
    }

    &__btn-close {
        background-color: inherit;
        border: none;
        cursor: pointer;
    }

    &__main-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100%);
        padding: 24px;
        gap: 24px;
    }

    .prepared_scenarios {
        display: flex;
        width: calc(100%);
        flex-direction: column;
        gap: 12px;
        overflow: auto;
        padding-bottom: 24px;
        margin-top: 12px;
        max-height: calc(100% - 280px);
        height: 100%;

        &__header {
            display: flex;
            gap: 10px;

            .title_category,
            .title_col {
                display: flex;
                gap: 16px;
                flex-wrap: wrap;
                color: #334155;
                font-family: Rubik;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            .title_category {
                width: 100%;
                min-width: 210px;
                max-width: 490px;

                button {
                    background-color: inherit;
                    border: none;
                    color: #00B45E;
                    font-family: NunitoSans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    margin-left: auto;
                }
            }

            .title_col {
                min-width: 210px;
                max-width: 210px;

                &:nth-child(2) {
                    min-width: 300px;
                    max-width: 300px;
                }

                &:last-child {
                    min-width: 180px;
                    max-width: 180px;
                }
            }
        }

        .selector_wrapper {
            &:nth-child(2) {
                min-width: 300px;
                max-width: 300px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 12px;
            height: 100%;

            .btn-setting {
                width: 40px;
                height: 40px;
                min-width: 40px;
                border: none;
                border-radius: 8px;
                background: #FAFBFA;
                background-image: url('./img/setting_icon.svg');
                background-repeat: no-repeat;
                background-position: center;
            }

            .row-item {
                display: flex;
                width: 100%;
                align-items: flex-start;
                gap: 10px;

                &__container {
                    display: flex;
                    align-items: center;
                    min-width: 180px;
                    gap: 24px;
                }

                .category-item {
                    display: flex;
                    min-height: 40px;
                    padding: 10px 20px;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap: 10px;
                    border-radius: 8px;
                    border-bottom: 4px solid #FBC184;
                    background: #F6F7FD;
                    width: 100%;
                    overflow: hidden;
                    color: #020617;
                    font-family: Rubik;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    width: 100%;
                    max-width: 490px;
                    min-width: 210px;

                    &__input {
                        color: #020617;
                        font-family: Rubik;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        background-color: inherit;
                        border: none;

                        &:focus {
                            outline: none;
                        }
                    }

                    img {
                        width: 20px;
                        height: 20px;
                        margin-left: auto;
                        display: none;
                        cursor: pointer;
                    }

                    &:hover {
                        img {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}