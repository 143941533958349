@import 'src/common/styles/svg_img.scss';

$blue: #1890FF;
$green: #27BC62;
$yellow: #FA9C0F;
$red: #FA0F0F;

.task-item {
    display: flex;
    gap: 8px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid #EDEFED;
    background: #FFF;
    padding: 8px;
    position: relative;
    cursor: grab;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    &:active {
        cursor: grabbing;
    }

    &:hover {
        transition: 0.5s;
        border: 1px solid #F2F4F2;
        background-color: #F2F4F2;
    }

    &:hover {
        .context-more {
            opacity: 1;
            visibility: visible;
            transition: 0.5s;

            .drop-menu_active {
                opacity: 1;
                visibility: visible;
                min-width: 170px;
                width: 170px;

                .drop-menu__btn {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    height: 32px;
                }
            }
        }
    }

    .priority {
        display: flex;
        align-items: center;
        gap: 4px;

        .text {
            color: #0B110B;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px
        }

    }

    .context-more {
        position: absolute;
        opacity: 1;
        visibility: hidden;
        right: 9px;
        top: 12px;

        .btn-more {
            display: flex;
            background-color: #F5F7F8;
            border: 1px solid #E0E7F2;
            border-radius: 4px;
            background-size: 20px;
            background-position: center;
        }

        .drop-menu {
            min-width: 170px;
            width: 170px;
        }

        .delete {
            color: #F88181;
        }

        .drop-menu_active {
            height: auto;
            opacity: 0;
            visibility: hidden;

            .drop-menu__btn {
                height: 32px;
            }
        }
    }

    .info-container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .text {
            color: #020617;
            text-overflow: ellipsis;
            font-family: NunitoSans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.07px;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 3; // количество строк
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            overflow: hidden;
            hyphens: auto;
            max-width: 160px;
        }



        .info-status-color_green,
        .info-status-color_yellow,
        .info-status-color_grey,
        .info-status-color_blue,
        .info-status-color_red {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 18px;
            height: auto;
            padding: 4px 8px;
            align-items: center;
            border-radius: 4px;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;

            p {
                display: -webkit-box;
                text-align: center;
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                -webkit-line-clamp: 2; // количество строк
                -webkit-box-orient: vertical;
                word-break: break-word;
                overflow: hidden;
                hyphens: auto;
            }

        }

        .info-status-color_green {
            background: #BBF7D0;
            color: #15803D;
        }

        .info-status-color_yellow {
            background: #FFEDD5;
            color: #FB923C;
        }

        .info-status-color_grey {
            background: #F1F5F9;
            color: #64748B;
        }

        .info-status-color_blue {
            background: #E7F1FF;
            color: #1890FF;
        }

        .info-status-color_red {
            background: #FECACA;
            color: #EF4444;
        }

        .right-content {
            display: flex;
            gap: 8px;
            flex-wrap: nowrap;
            align-items: center;
            margin-left: auto;

            .text {
                white-space: nowrap;
            }
        }


        .status_red {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #FECACA;
            border: 1px solid #EF4444;
        }

        .status_yellow {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #FFEDD5;
            border: 1px solid #FDBA74;
        }

        .status_green {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #BBF7D0;
            border: 1px solid #15803D;
        }
    }

    .list-members {
        display: flex;
        width: 100%;
        padding-top: 8px;
        border-top: 1px solid #EDEFED;
        min-height: 32px;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;

        .members-list-box {
            display: flex;
            justify-content: space-between;
            gap: 8px;

            img {
                min-width: 24px;
                max-width: 24px;
                height: 24px;
            }
        }

        .text_blue {
            color: #1890FF;
            font-size: 12px;
        }

        .term-box {
            display: flex;
            flex-direction: column;
            gap: 2px;
            align-items: flex-end;
            border-radius: 4px;
            margin-left: auto;

            &__top {
                display: flex;
                align-items: center;
                gap: 4px;

                .text_grey {
                    color: #636D65;
                    font-family: NunitoSans;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                }

                .text {
                    color: #020617;
                    font-family: NunitoSans;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 12px;
                }
            }
        }

        .text_date {
            color: #C5CCD3;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .btn-and-options-box {
        position: absolute;
        top: 16px;
        right: 24px;
        cursor: pointer;
        border: none;

        .btn__points {
            width: 20px;
        }

        .options-box {
            display: flex;
            width: 150px;
            flex-direction: column;
            border-radius: 16px;
            padding: 4px 0;
            background-color: white;
            position: absolute;
            -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            right: -24px;
            z-index: 5;
            overflow: hidden;
            cursor: pointer;

            .text {
                font-size: 12px;
                cursor: pointer;
                padding: 4px 16px;

                &:hover {
                    background-color: #E6F7FF;
                    transition: color 0.5s;
                }
            }
        }

    }

    .number-of-cards {
        display: flex;
        margin-top: 16px;
        gap: 16px;
        align-items: center;

        .text_grey {
            color: #AAAAAA;
            font-size: 12px;
        }

        .text_number {
            font-size: 20px;
        }
    }

    .notes-box {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 46px;

        svg {
            margin-top: 10px;
        }

        .list-members {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .term-box {
            display: flex;
            gap: 4px;
            align-items: center;
            padding: 9px 16px;

            .text {
                color: #53616E;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }


        .flag.blue {
            path {
                stroke: $blue;
            }
        }

        .flag.green {
            path {
                stroke: $green;
            }
        }

        .flag.yellow {
            path {
                stroke: $yellow;
            }
        }

        .flag.red {
            path {
                stroke: $red;
            }
        }

        .term-box.blue {
            background-color: #E6F7FF;

            .time-svg {
                stroke: #1890FF;
            }

            .text {
                color: #1890FF;
            }
        }

        .term-box.green {
            background-color: rgba(207, 238, 141, 0.3);

            .time-svg {
                stroke: #27BC62;
            }

            .text {
                color: #27BC62;
            }
        }

        .term-box.yellow {
            background-color: #FFF7AC;

            .time-svg {
                stroke: $yellow;
            }

            .text {
                color: $yellow;
            }
        }

        .term-box.red {
            background-color: rgba(255, 172, 172, 0.3);

            .time-svg {
                stroke: #FA0F0F;
            }

            .text {
                color: #FA0F0F;
            }
        }
    }
}

.btn__points {
    width: 3px;
    height: 17px;
    cursor: pointer;
    border: none;
    background-color: inherit;
    background-image: url($points);
    background-repeat: no-repeat;
    background-position: center;
}