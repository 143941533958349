.cross-analytics {
    display: flex;
    width: 100%;
    gap: 24px;
    flex-direction: column;

    &__header {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
    }

    &__range {
        display: flex;
        gap: 12px;
    }

    &__btn-download {
        display: flex;
        gap: 8px;
        align-items: center;
        color: #0B110B;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        height: 32px;
        padding: 4px 14px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: #FFF;
        border-radius: 8px;
        border: 1px solid #EDEFED;
        width: 100%;
    }

    &__btn-group {
        display: flex;
        gap: 6px;
        width: 100%;
        margin-top: 10px;
        flex-direction: column;
    }

    .title_main {
        color: #0C2037;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

}