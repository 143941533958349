@import 'src/common/styles/svg_img.scss';

.analytics-page {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .summary {
        display: flex;
        width: 1005;
        height: auto;
        padding: 33px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 2px solid #EDEFED;
        background: #FFF;

        &__title {
            color: #0C2037;
            font-family: "Golos Text";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &__content {
            display: flex;
            gap: 50px;
            flex-wrap: wrap;
        }

        &__card {
            display: flex;
            flex-direction: column;
            align-items: start;
            width: 220px;
            gap: 18px;
        }

        &__placeholder {
            color: #0C2037;
            font-family: "Golos Text";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        &__sum {
            color: #0C2037;
            text-align: right;
            font-family: "Golos Text";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
    }

    .title-main-content {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 12px;

        .title {
            font-size: 24px;
            color: #020617;
        }
    }

    .connections_page_selector.breadCrumbs {
        display: none;
    }

    .range-group-btn-and-range-box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;

        .selector_wrapper {
            max-width: 160px;
            min-width: 160px;
        }

        .drop-box {
            display: flex;
            align-items: center;
            gap: 16px;

            .selector_wrapper {
                * {
                    text-align: center;
                }
            }

            .css-1dimb5e-singleValue {
                text-align: center;
            }
        }
    }

    .toolbar-top {
        display: flex;
        width: 100%;
        gap: 16px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        // padding-right: 250px;

        .filter-group {
            display: flex;
            gap: 13px;
            // width: calc(350px*2 + 13px*2 + 260px);
            min-width: calc(400px);
            // flex-wrap: wrap;
        }

        .btn-group {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }

    .toolbar-medium {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    .tab-box {
        display: flex;
        width: auto;
        row-gap: 12px;
        flex-wrap: wrap;

        .btn__tab,
        .btn__tab_active {
            cursor: pointer;
            height: 34px;
            min-width: 130px;
            width: auto;
            font-size: 16px;
            font-weight: 500;
            background-color: inherit;
            border: none;
            padding-right: 20px;
            padding-left: 20px;
            white-space: nowrap;
            line-height: 20px;
            border-bottom: 2px solid #E0E7F2;

            &:hover {
                transition: 0.5s;
                border-bottom: 2px solid #1890FF;
                color: #1890FF;
            }

            &_active {
                border-bottom: 2px solid #1890FF;
                color: #1890FF;
            }
        }
    }

    .charts-container {
        width: 100%;
        display: flex;
        background-color: white;
        border-radius: 8px;

        .charts-group {
            display: flex;
            width: 100%;
            gap: 2%;

            .chart-box{
                width: 100%;

                canvas{
                    width: 100%;
                    max-width: 100%;
                }
            }

            .cup-component {
                width: 38%;
            }
        }
    }


    .pie-chart {
        margin-top: 16px;
    }
}