.post.modal_post{    
    .comment-list{
        width: 60%;
    }

    .comment-content {
        height: 100%;
    }

    .post-item{
        width: 40%;
        max-width: 490px;
        border-left: 2px solid #EDEFFB;
    }
}