.account-safety {
    display: flex;
    flex-direction: column;
    
    .link__blue {
        display: inline-block;
        width: 100%;
        text-align: right;
        color: #1890FF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: underline;
        margin-left: auto;
        margin-top: 4px;
    }
}