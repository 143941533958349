.connections {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
    position: relative;

    .title_main {
        color: #0C2037;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    .plus {
        min-width: 196px;
        max-width: 196px;
        text-align: left;
        padding-left: 8px;
        background-image: url('./img/add_icon.svg');
        background-repeat: no-repeat;
        background-position: center right 8px;
    }


    .filter-box {
        display: flex;
        gap: 24px;
        align-items: center;


        .text {
            white-space: nowrap;
        }

        .selector_wrapper {
            width: 224px;
            min-width: 224px;
            min-height: 32px;
        }

        .selector_wrapper {
            max-width: 200px;
            border: 1px solid #F0F0F0;
        }
    }

    & .btn__green {
        margin-top: 0;
        height: 32px;
        min-width: 200px;
        max-width: 200px;
    }
}