.modal-creating-new-space {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 1088px;
    width: 100%;
    min-width: 360px;
    height: 100%;
    max-height: 700px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        padding: 24px;
        width: 100%;

        .title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #020617;
            font-family: NunitoSans;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.56px;
        }
    }

    .tabs {
        padding: 12px 24px 0 24px;
    }

    .tabs_big {

        .tabs {
            padding: 0;
        }

        .tabs__item,
        .tabs__item_active {
            &:nth-child(1) {
                padding: 26px;
                width: 100%;
                max-width: 50%;
                justify-content: center;
            }

            justify-content: center;
            padding: 26px;
            width: 100%;
            max-width: 50%;
        }
    }

    .toolbar-medium {
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;
        padding: 24px;
        border-top: 1px solid #EDEFED;
        border-bottom: 1px solid #EDEFED;
    }

    &__content {
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding: 24px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        max-height: calc(100% - 96px - 84px - 32px);
        row-gap: 28px;
        column-gap: 12px;
    }

    &__btn-box {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: end;
        padding: 8px 24px;
        margin-top: auto;
        border-top: 1px solid #EDEFED;
    }

    .input-field-wrapper {
        max-width: 100%;
    }

    .no-executor {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        height: 100%;

        .text_grey {
            text-align: center;
            color: #94A3B8;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .notification-item,
    .notification-item_active {
        display: flex;
        gap: 8px;
        width: 150px;
        min-width: 150px;
        padding: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 2px solid #EDEFFB;
        background: #FFF;
        cursor: pointer;
        position: relative;

        .text,
        .text_mp,
        .text_grey {
            overflow: hidden;
            color: #020617;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            word-break: break-word;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }

        .text_grey {
            color: #8c8c8c;
        }

        .text_mp {
            display: flex;
            align-items: center;
            gap: 8px;

            img {
                width: 24px;
                height: 24px;
            }
        }

        .changing-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;
            align-items: center;
            min-height: 32px;
            overflow: hidden;
        }

        &:hover {
            border: 2px solid #3900B1;
            transition: 0.5s;

        }

        &_active {
            border: 2px solid #3900B1;

            .text {
                display: inline-block;
            }
        }

        img {
            margin-left: auto;
            margin-right: auto;
            object-fit: contain;
            width: 40px;
            height: 40px;
        }
    }
}