.modal-card-created {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 550px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        color: #020617;
        text-align: center;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        width: 100%;
    }

    &__info-content {
        display: flex;
        width: 100%;
        padding: 12px 16px;
        flex-direction: column;
        align-items: center;
        gap: 18px;
        border-radius: 12px;
        border: 2px solid #EDEFFB;

        .title {
            color: #11195D;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }

        .content-points {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 12px;

            .point-item {
                display: flex;
                width: 140px;
                min-width: 140px;
                flex-direction: column;
                gap: 10px;
                align-items: center;

                .text {
                    color: #64748B;
                    text-align: center;
                    font-family: Rubik;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }

                .icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .text {
        color: #64748B;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        .link_blue {
            @extend .text;
            color: #3900B1;
            text-decoration: underline;
        }
    }

    img {
        width: 100px;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
    }

    .btn-group {
        display: flex;
        width: 100%;
        gap: 12px;
    }
}