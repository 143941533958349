.modal-creating-connections {
    max-width: 1271px;
    width: 100%;
    min-width: 360px;
    gap: 24px;
    min-height: 360px;
    height: 100%;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    position: relative;

    &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 16px 32px;
        border-radius: 8px 8px 0 0;
        border-bottom: 2px solid var(--Stroke-Primary-Primary, #EDEFFB);
        background: #F8F9FE;

        .title {
            color: #020617;
            font-family: Rubik;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.56px;
        }
    }

    .toolbar-medium {
        display: flex;
        align-items: flex-start;
        padding-left: 32px;
        padding-right: 32px;
        gap: 24px;

        .selector_wrapper {
            max-width: 100%;
        }

        .right-content {
            display: flex;
            flex-direction: column;
            width: 200px;
            gap: 8px;

            .counter-product {
                display: flex;
                gap: 4px;
                width: 100%;
                padding: 4px;
                align-items: center;
                border-radius: 8px;
                background: #EDEFFB;

                .text {
                    color: #8278F0;
                    font-family: Rubik;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        height: 100%;
        padding: 32px;
        padding-top: 0;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .option-master-product {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        .text_article {
            color: #555C9E;
            font-family: Rubik;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            white-space: nowrap;

            span {
                color: #555C9E;
                font-weight: 400;
            }
        }

        .text {
            color: #020617;
            font-family: Rubik;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
        }
    }
}