.modal-mass-improvement {
    max-width: 1200px;
    width: 100%;
    height: 100%;
    max-height: 800px;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    background: #FFF;

    &__header {
        display: flex;
        gap: 32px;
        width: 100%;
        padding: 16px 32px;
        border-bottom: 2px solid var(--Stroke-Primary, #EDEFFB);
        background: #F8F9FE;

        .title_main {
            color: #020617;
            font-family: Rubik;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        width: 100%;
        height: calc(100% - 66px);
        padding: 32px;
        overflow: auto;

        .toolbar {
            display: flex;
            gap: 12px;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .text {
                color: #000;
                font-family: Rubik;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }

            .right-content {
                display: flex;
                gap: 12px;
                width: auto;

                .btn {
                    display: flex;
                    height: 32px;
                    padding: 4px 12px;
                    align-items: center;
                    gap: 8px;
                    align-self: stretch;
                    border-radius: 8px;
                    background: #F8F9FE;
                    border-radius: 8px;
                    border: 2px solid #EDEFFB;
                    overflow: hidden;
                    color: #555C9E;
                    text-overflow: ellipsis;
                    font-family: Rubik;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                }
            }
        }

        .textarea-dynamic-placeholder {
            height: 100%;
            max-height: calc(100% - 56px - 24px - 32px - 12px);

            &__content {
                height: 100%;
            }
        }
    }

    .list_container{
        width: 100%;
        height: 90%;
        display: flex;
        overflow: hidden;
        gap: .5em;

        .product_list{
            width: 25%;
            height: 100%;
            border: 1px solid #edefed;
            box-sizing: border-box;
            padding: 1em .75em;
            border-radius: 10px;

            .product_list_header{
                display: flex;
                gap: 1em;
                margin-bottom: 1em;

                .product_list_header_text{
                    font-size: 18px;
                    font-weight: bold;
                }

                .product_list_header_checkbox{
                    display: flex;
                    gap: .5em;
                    font-size: 14px;
                    align-items: center;
                }
            }

            .product_list_container{
                width: 100%;
                height: calc(100% - 2em);
                overflow: overlay;
                display: flex;
                flex-direction: column;
                gap: .5em;

                .product_list_item{
                    width: 98%;
                    border: 1px solid #edefed;
                    box-sizing: border-box;
                    border-radius: 10px;
                    min-height: 70px;
                    display: flex;
                    box-sizing: border-box;
                    padding: .5em;
                    gap: .5em;
                    align-items: flex-start;
                    cursor: pointer;
                    transition: .3s;

                    .product_list_item_image{
                        width: 42px;
                        height: 52px;
                        object-fit: contain;

                        border: 1px solid #edefed;
                        border-radius: 5px;
                    }

                    .product_list_item_info_container{
                        display: flex;
                        flex-direction: column;
                        gap: .15em;

                        .product_list_item_header{
                            font-size: 14px;
                            color: grey;
                        }
                        .product_list_item_substr{
                            color: #b8b8b8;
                            font-size: 12px;
                        }
                    }
                }
            }

        }

        .product-improvement-inside-content__changed-content{
            width: 70%;
            height: 100%;

            .emoji-addition-inside-content{
                height: 100%;
            }

            .nodata_wrapper{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .container{
                    height: 450px;
                    width: 450px;
                    box-sizing: border-box;
                    padding: 2em 1em;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: 1em;

                    .container_header{
                        font-size: 20px;
                    }

                    .container_description{
                        font-size: 15px;
                        color: #68727D;
                    }
                }
            }

            .status_wrapper{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;


                .spinner_container{
                   display: flex;
                   flex-direction: column;
                   align-items: center;

                    .counter{
                        font-size: 32px;

                        .status_total{
                            color: #AFAFAF;
                        }
                    }
                }


                .container{
                    height: 400px;
                    width: 450px;
                    box-sizing: border-box;
                    padding: 2em 1em;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: 1em;

                    .spinner_container {
                        display: flex;
                        flex-direction: column;
                        gap: 1em;
                    }

                    .container_header{
                        font-size: 62px;
                        color: #00B45E;
                    }

                    .container_description{
                        font-size: 15px;
                    }
                }
            }
        }
    }
}