.products-list-admin {
    padding: 20px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

   .toolbar-top {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;

    .btn__green {
        margin-top: 0;
        max-height: 32px;
    }

    .selector_wrapper {
        width: 224px;
        min-width: 224px;
    }
} 
}

