.container {
    display: flex;
    flex-direction: column;
}

._3Z0JX {
    background: forestgreen;
}

.drop_zone {
    width: 236px;
    height: 100%;
    border-radius: 8px;
    border: 1px #E7E7E7 solid;
    min-height: auto;
    height: 100%;
    padding: 24px;
    overflow-x: hidden;
    overflow-y: auto;
}

.box {
    padding: 0;
}

.btn {
    background-color: white;
    color: #767676;
    text-align: center;
    text-overflow: ellipsis;
    font-family: NunitoSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    background-image: url('./img/bx-plus-circle.svg.svg');
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 20px;

    &:hover {
        background-color: white !important;
    }
}

.btn {}