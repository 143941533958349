.tabs-green {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    height: 36px;
    padding: 4px;
    gap: 2px;
    background: #ECECEC;
    width: fit-content;

    &__content {
        display: flex;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
    }

    .text {
        color: #636D65;
        text-align: center;
        font-family: NunitoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
    }

    .count {
        border-radius: 4px;
        background: #E1E6E2;
        padding: 2px 4px;
        color: #636D65;
        text-align: center;
        font-family: NunitoSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
    }

    .btn,
    .btn_active {
        background-color: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 8px;
        padding-right: 8px;
        gap: 8px;
        height: 28px;
        white-space: nowrap;
        border: none;
        background-color: inherit;
        color: #767676;
        text-align: center;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;

        &:hover,
        &:active,
        &_active {
            transition: 0.5s;
            border-radius: 4px;
            background: #FFF;
            color: #00B45E;

            .count {
                color: #0B110B;
            }
        }
    }
}