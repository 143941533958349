.modal-creating-an-advertising-campaign-v2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    max-width: 1600px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 40px);
    border-radius: 8px;
    background: var(--white, #FFF);
    overflow: hidden;
    border-radius: 8px;
    height: 100%;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        display: flex;
        gap: 24px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px 20px 16px 32px;
        border-bottom: 2px solid #EDEFFB;
        background: #F8F9FE;

        .title {
            color: #020617;
            font-family: Rubik;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            margin: 0;
        }

        .btn-close {
            width: 40px;
            height: 40px;
            background-image: url('./img/close_icon.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-color: inherit;
            border: none;
            cursor: pointer;
        }
    }

    .tabs {
        padding-left: 30px;
        width: 100%;
        max-width: 1334px;
        margin-left: auto;
        margin-right: auto;
    }

    &__content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        height: calc(100vh - 74px - 40px);
        overflow: auto;
        gap: 24px;

        &::-webkit-scrollbar {
            display: none;
        }

        .left-panel {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 440px;
            max-width: 440px;
            justify-content: space-between;
            box-shadow: 0px 12px 62px 0px rgba(24, 0, 91, 0.08);
            min-height: calc(100vh);
   //         height: calc(100% + 74px);

            .btn-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 32px;
                margin-bottom: 98px;
            }

            .steps-content {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                gap: 40px;
                padding: 32px 32px;
                margin-left: auto;
                margin-right: auto;

                .step-content {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;

                    &__header {
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        .step_number {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 24px;
                            height: 24px;
                            min-width: 24px;
                            background-color: #D3DEE7;
                            border-radius: 50%;
                            color: #F9FAFB;
                            font-family: IBMPlexSans;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                        }

                        .title_step {
                            color: #020617;
                            font-family: Rubik;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 24px
                        }
                    }

                    &__content {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                    }

                    .input-box,
                    .input-box__err {
                        margin: 0;
                    }

                    .field-group {
                        display: flex;
                        gap: 12px;
                        align-items: flex-start;

                        .range-box-single .date-picker .react-datepicker-popper {
                            margin-top: 10;
                        }

                        .range-box-single {
                            height: 40px;
                            width: calc(50% - 6px);
                            min-width: auto;

                            .calendar-img {
                                display: none;
                            }

                            &__tooltip {
                                margin-top: 0px;
                            }

                            .selector_wrapper {
                                height: 40px;
                            }
                        }
                    }

                }
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .btn-group {
        display: flex;
        width: 100%;
        justify-content: end;
        align-items: center;
        gap: 12px;
    }

    .steps-items {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;

        .step-item,
        .step-item_active {
            display: flex;
            gap: 12px;
            align-items: center;

            .step-text {
                color: #8F99A3;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            &_active {
                .step-num {
                    background: #BBF7D0;
                }

                .step-text {
                    color: #020617;
                    font-weight: 500;
                }
            }
        }
    }

    .add-campaign {
        display: flex;
        width: 100%;
        padding: 24px;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #E0E7F2;
        background: #F5F7F8;

        .input-field-group {
            display: flex;
            flex-direction: column;
            width: 100%;

            .MuiFormControl-root {
                margin-top: 0;
            }

            .input-box {
                margin-top: 0px;
            }

            .double-group {
                display: flex;
                align-items: center;
                gap: 12px;

                .input-field-item {
                    display: flex;
                    flex-direction: column;
                    height: 40px;
                    width: calc(50% - 6px);
                    gap: 12px;

                    .component-date {
                        display: flex;
                        height: 40px;
                        padding: var(--space-x2, 8px) var(--space-x5, 20px);
                        align-items: center;
                        gap: var(--space-x2, 8px);
                        align-self: stretch;
                        border-radius: 8px;
                        border: 1px solid var(--table-border, #E0E7F2);
                        background: var(--white, #FFF);
                        color: var(--all-text-teritary-text, #94A3B8);
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        line-height: 16px;
                    }
                }

                .title {
                    color: var(--all-text-secondary-text, #475569);
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    /* 150% */
                }
            }
        }

        .selected-strategy {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 8px;
            margin-top: 32px;

            .link_grey {
                display: flex;
                align-items: center;
                gap: 8px;
                color: #8F99A3;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                cursor: pointer;
            }

            .text {
                color: #020617;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }

    .table-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        padding: 32px 24px;
        width: 100%;
      //  height: calc(100% + 74px);
        overflow: auto;

        .table-without-data {
            height: 700px;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .child-content {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 1em;
        }

        .group-btn {
            display: flex;
            align-items: center;
            column-gap: 12px;
            row-gap: 8px;
        }
    }
}

