.slider-panel {
    display: flex;
    gap: 32px;
    align-items: center;
  //  margin-top: 40px;
    width: 100%;

    .slider-box {
        display: flex;
        max-width: 100%;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        gap: 10px;
        flex-wrap: nowrap;
        align-items: center;
        gap: 20px;

        .slider-num {
            min-width: 70px;
            width: 70px;
            height: 32px;
            font-size: 14px;
            padding: 10px;
            color: #1890FF;
            background-color: white;
            border-radius: 8px;
            border: 1px solid #ffffff;
            align-items: center;
            justify-content: center;
            justify-content: center;
            text-align: center;

            &:focus {
                outline: none;
                border: 1px solid #4DA9FF;
            }

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        .rc-slider-handle {
            box-shadow: rgba(0, 0, 0, 0.05);
            border: white 4px solid;
            opacity: 1;
            background-color: #1890FF;
            -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        }

        .rc-slider-track {
            background-color: #1890FF;
        }
    }

    .input-segment-box {
        display: flex;
        gap: 15px;
        align-items: center;

        .btn__blue {
            border-radius: 8px;
            font-size: 13px;
            width: 88px;
            min-width: 88px;
        }

        .input-box {
            position: relative;
            margin-top: 0;

            .text_grey {
                top: calc(50% - 9px);
                left: 10px;
                color: #9F9F9F;
                font-size: 14px;
                position: absolute;
            }
        }

        input {
            display: flex;
            width: 148px;
            height: 32px;
            padding: 10px;
            align-items: center;
            gap: 12px;
            flex-shrink: 0;
            border-radius: 8px;
            border: 1px solid var(--table-border, #E0E7F2);
            background: #FFF;
            padding-left: 97px;
            color: #020617;
            padding-right: 12px;
            text-align: right;

            &:focus {
                //border: 1px solid #4DA9FF;
                outline: none;
            }

            &::placeholder {
                color: #9F9F9F;
            }
        }

        .btn-group {
            position: absolute;
            width: 12px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            top: 0;
            right: 11px;
            gap: 4px;
            pointer-events: none;

            .btn_top,
            .btn_bottom {
                width: 12px;
                height: 50%;
                border: none;
                background-image: url('./img/arrow_delta_top.svg');
                background-repeat: no-repeat;
                background-position: bottom;
                pointer-events: none;
                background-color: inherit;
            }

            .btn_bottom {
                background-image: url('./img/arrow_delta_bottom.svg');
                background-position: top;
            }
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            //-webkit-appearance: none;
            opacity: 0;
        }
    }
}