@import 'src/common/styles/svg_img.scss';

.landing-products {
    &__block_long {
        background-image: url($background);
        background-repeat: no-repeat;
    }

    &__changes-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        height: 100%;

        .title {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            font-size: 24px;
        }

        .text {
            margin-top: 27px;
            font-size: 20px;
            text-align: center;
            font-weight: 400;
        }

        .btn-circle-group {
            margin-top: 0;
        }
        
    }

    &__content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 24px 40px;

        &__header-box {
            padding: 0;
        }

        .title {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            font-size: 24px;
        }

        .text {
            margin-top: 27px;
            font-size: 20px;
            text-align: center;
            font-weight: 400;
        }

        .btn__green {
            max-width: 100%;
            height: 56px;
            margin-top: 0;
            font-size: 24px;
            font-weight: 500;
        }
    }
}

.bkg-grey-dark {
    background-color: #888888;
}

.content__img {
    display: inline-block;
    width: 203px;
}