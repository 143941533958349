.analitics-review {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    &__chart-group {
        width: 100%;
        gap: 24px;
        display: flex;
    }

    .line_chart {
        background-color: #FFF;
    }

    &__title-table {
        color: #000;
        font-family: NunitoSans;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        display: -webkit-box;
        -webkit-line-clamp: 1; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
    }

    &__table-item {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;

        .table-paginator-box {
            border: 1px solid #E0E7F2 !important;
        }
    }

    .table-paginator-box {
        border: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }

    .title_main {
        color: var(--slate-950, #020617);
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 114.286% */
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    .name-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        color: #4338CA;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.32px;
    }

    .groop-container {
        display: flex;
        flex-direction: column;
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        gap: 24px;

        &__text {
            color: #0B110B;
            font-family: NunitoSans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;

        }

        &__charts,
        &__tables {
            display: flex;
            gap: 24px;
        }

        &__chart-and-table {
            display: flex;
            gap: 24px;

            .table-box-component {
                width: 40%;
            }

            .line_chart {
                max-width: 60%;
            }
        }
    }

    // Продвинутая аналитика

    .charts-and-table-content {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 8px;
        row-gap: 24px;

        .title_table {
            overflow: hidden;
            color: #878ED2;
            text-overflow: ellipsis;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2; // количество строк
            -webkit-box-orient: vertical;
            overflow: hidden;
            hyphens: auto;
        }

        .line_chart {
            width: 60%;
            max-width: calc(60% - 4px);

            &:nth-child(1) {
                width: 100%;
                max-width: 100%;
            }

            &:nth-child(2) {
                width: 60%;
                max-width: calc(60% - 4px);
            }
        }

        .table-box-component {
            max-width: calc(100%);
        }

        // .chart-group,
        // .table-group {
        //     max-width: calc(60% - 4px);


        // }

        // .chart-group {
        //     .line_chart {
        //         width: 100%;
        //         max-width: 100%;
        //     }

        //     .table-box-component {
        //         max-width: calc(40% - 4px);
        //     }
        // }

        // .table-group {
        //     max-width: calc(60% - 4px);

        //     .table-box-component {
        //         max-width: calc(100% - 4px);
        //     }
        // }
    }

    .chart-group,
    .table-group {
        display: flex;
        width: 100%;
        gap: 8px;

        .metrics-group {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }



    .by-product-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        .cup-and-chart {
            display: flex;
            width: 100%;
            gap: 12px;

            .cup-component,
            .line_chart {
                width: calc(50% - 4px);
            }
        }

        .chart-group {
            display: flex;
            gap: 8px;
            width: 100%;
        }

        .сard-statistics {
            width: 206px;
        }

        .card-box {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 12px;
            width: 100%;
        }

        .card-box_min {
            display: flex;
            flex-direction: column;
            gap: 8px;
            min-width: 206px;
            max-width: 206px;
        }
    }

    &__toolbar-top {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 12px;
        flex-wrap: wrap;

        .filters-container {
            max-width: fit-content;
        }
    }


}

.review-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    width: 100%;

    &__top-content {
        display: flex;
        background-color: #fff;
        border: 1px solid #EDEFED;
        border-radius: 8px;
        width: 100%;
        min-height: 80px;
        height: auto;
        max-height: 400px;
        margin-bottom: 16px;

        .card-group {
            display: flex;
            height: 100%;
            width: calc(100%/3*2);

            .card-item {
                display: flex;
                flex-direction: column;
                width: calc(100%/3);
                padding: 10px 16px;
                justify-content: space-between;
                align-items: flex-start;
                height: 100%;

                .text {
                    color: #575D57;
                    font-family: NunitoSans;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; // количество строк
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    hyphens: auto;
                }

                .text_sum {
                    color: #0B110B;
                    font-family: NunitoSans;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 28px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; // количество строк
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    hyphens: auto;
                }

                .tag-item {
                    display: flex;
                    padding: 2px 8px;
                    align-items: center;
                    gap: 4px;
                    border-radius: 128px;
                    background: #FAFBFA;

                    .text {
                        color: #0B110B;
                        font-family: NunitoSans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; // количество строк
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        hyphens: auto;
                        word-break: break-word;
                    }
                }
            }
        }

        .tone-feedback {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            width: calc(100%/3);
            padding: 10px 20px;

            .title {
                color: #575D57;
                font-family: NunitoSans;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
            }

            &__content {
                display: flex;
                gap: 8px;

                .dynamic-tone {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    width: calc(50% - 4px);

                    &__top {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: space-between;
                        gap: 24px;

                        .text {
                            color: #000;
                            font-family: NunitoSans;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 14px;
                            display: -webkit-box;
                            -webkit-line-clamp: 1; // количество строк
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            hyphens: auto;
                        }

                        .text_percent {
                            @extend .text;
                            font-weight: 700;
                        }
                    }

                    .dynamic_positive,
                    .dynamic_negative {
                        width: 100%;
                        height: 5px;
                        background-color: #DC2626;

                        .dynamic_bkg {
                            height: 100%;
                            background-color: #51CA64;
                        }
                    }

                    .dynamic_negative {
                        background-color: #51CA64;

                        .dynamic_bkg {
                            background-color: #DC2626;
                        }
                    }
                }
            }
        }
    }

    &__content {
        display: flex;
        background-color: #fff;
        border: 1px solid #EDEFED;
        border-radius: 8px;
        width: 100%;
        min-height: 650px;
        height: auto;
        max-height: 400px;

        .sort-group-btn-group {
            display: flex;
            gap: 8px;
            align-items: center;
        }

        .sort-group {
            display: flex;
            gap: 8px;
            width: 100%;
            justify-content: space-between;
            padding: 12px 16px 0;


            .btn-sort {
                display: flex;
                padding: 8px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 6px;
                border: 1px solid var(--Stroke-Primary, #EDEFED);
                background: #FFF;
                color: #636D65;
                font-family: NunitoSans;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                cursor: pointer;
            }

            .text_sum {
                display: flex;
                align-items: center;
                gap: 4px;
                color: #0B110B;
                text-align: center;
                font-family: NunitoSans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                white-space: nowrap;

                span {
                    display: inline-block;
                    background-color: #E1E6E2;
                    padding: 4px;
                    border-radius: 4px;
                    color: #0B110B;
                    text-align: center;
                    font-family: NunitoSans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 14px;
                }
            }
        }

        .review-list,
        .table-content,
        .product-content {
            width: calc(100%/3);
            height: 100%;
            border-right: 1px solid #EDEFED;
        }

        .review-list {
            border-left: none;

            &__header,
            &__header-bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                min-height: 56px;
                max-height: 56px;
                overflow: hidden;
                border-bottom: 1px solid #EDEFED;



                .input-dynamic-width-bar {
                    max-width: 100%;
                    width: 100%;
                    padding-left: 4px;

                    .input-dynamic-width-bar__btn_close {
                        right: 16px;
                    }
                }

                .search_active {
                    width: 100%;
                }

                .title {
                    color: #000;
                    font-family: NunitoSans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;


                    &:nth-child(1) {
                        min-width: calc(150px + 6px);
                    }

                    &:nth-child(2) {
                        width: 100%;
                    }
                }
            }

            &__header-bottom {
                padding: 14px 16px;
            }

            &__content {
                display: flex;
                //  padding: 8px 24px;
                flex-direction: column;
                border-radius: 8px 0px 0px 0px;
                //    padding: 12px;
                height: calc(100% - 85px);
                overflow-y: overlay;
                overflow-x: hidden;
            }


            .review-item,
            .review-item_active {
                display: flex;
                padding: 8px 12px;
                width: 100%;
                align-items: center;
                gap: 12px;
                flex-wrap: wrap;
                transition: 0.5s;
                cursor: pointer;
                height: fit-content;

                &__content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: calc(100%);
                    gap: 12px;

                    .text {
                        color: #0B110B;
                        font-family: NunitoSans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        width: 100%;

                        &:nth-child(1) {
                            min-width: 160px;
                        }
                    }

                    .info-sum {
                        display: flex;
                        flex-direction: column;
                        align-items: end;

                        .text_sum {
                            color: #B4B8B4;
                            font-family: Rubik;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 14px;
                            white-space: nowrap;

                            span {
                                color: #0B110B;
                                font-family: Rubik;
                                font-size: 11px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }

            .positive-bkg,
            .negative_bkg_active {
                &:hover {
                    transition: 0.3s;
                    background: #EDFAF0;
                }

                &_active {
                    background: #EDFAF0;
                }
            }



            .negative_bkg,
            .negative_bkg_active {
                &:hover {
                    transition: 0.3s;
                    background: #FBF6F6;
                }

                &_active {
                    background: #FBF6F6;
                }
            }
        }

        .table-content {
            .table-without-data {
                min-height: 400px;
            }

            .table-box-component {
                height: 100%;
            }

            .table-paginator-box {
                overflow-y: overlay;

            }
        }

        .product-content {
            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                min-height: 56px;
                max-height: 56px;
                overflow: hidden;
                border-bottom: 1px solid #EDEFED;

                .search_active {
                    width: 100%;
                }
            }

            &__content {
                height: calc(100% - 85px);
                overflow-y: auto;
                overflow-x: hidden;
            }

            .message {
                .text_feedback {
                    -webkit-line-clamp: 4;
                    color: #0009E8;
                }

                .title {
                    display: -webkit-box;
                    -webkit-line-clamp: 1; // количество строк
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    hyphens: auto;
                    color: #0B110B;
                    text-overflow: ellipsis;
                    font-family: Rubik;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 12px;
                }

                .rating-and-date {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    gap: 8px;

                    .rating-color-box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 0px 8px 0px 8px;
                        position: static;
                        width: 44px;
                        min-width: 44px;
                        height: 28px;
                        top: 16px;
                        right: 16px;
                        gap: 6px;
                    }

                    .tag-item {
                        display: flex;
                        padding: 2px 8px;
                        align-items: center;
                        gap: 4px;
                        border-radius: 128px;
                        background: #FAFBFA;

                        .text {
                            color: #0B110B;
                            font-family: NunitoSans;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            display: -webkit-box;
                            -webkit-line-clamp: 1; // количество строк
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            hyphens: auto;
                            word-break: break-word;
                        }
                    }
                }

                .text_rating {
                    overflow: hidden;
                    color: #0B110B;
                    text-overflow: ellipsis;
                    font-family: NunitoSans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                }
            }
        }
    }
}



.tooltip-content-box {
    display: flex;
    width: 100%;
    gap: 12px;
    flex-direction: column;


    &__bottom {
        display: flex;
        gap: 16px;
        justify-content: space-between;
        width: 100%;
    }
}