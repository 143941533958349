.modal-crm-sub-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .input-field-wrapper {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 40%;
    background: white;
    padding: 24px;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
    height: 100%;
    overflow-y: auto;
    animation: slideIn 0.5s ease-out;
  }

  &__main-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: 700px;
    width: 40%;
    background: white;
    padding: 24px;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
    height: 100%;
    overflow-y: auto;
    animation: slideIn 0.5s ease-out;
  }

  &__title {
    color: #020617;
    font-family: Rubik;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 114.286% */
    letter-spacing: -0.56px;
  }

  &__label-text {
    color: #222;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  &__label, &__label_checked {
    display: flex;
    width: 100%;
    gap: 16px;
    border-radius: 8px;
    padding: 8px 16px;
    border: 2px solid #fff;

    &_checked,
    &:hover {
      transition: 0.5s;
      border: 2px solid #EDEFFB;
      background: #F1F2FD;
    }
  }

  &__btn-group {
    display: flex;
    gap: 12px;
    width: 100%;
    margin-top: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__checkbox-group {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }

}


@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}