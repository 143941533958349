.CRM {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    .title_main {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    .toolbar-top {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
    }
}