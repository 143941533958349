.tools__info-box {
   display: flex;
   gap: 22px;
   align-items: center;
   justify-content: flex-start;
   background-color: #FFF7AC;
   border: 1px solid #D9D9D9;

   .group__btn {
      width: auto;
      display: flex;
      align-items: center;
      gap: 16px;
      justify-self: flex-end;
      margin-left: auto;
   }
}

.client_link_input {
   width: 100%;
   border-radius: 16px;
   border: none;
   color: #020617;
   padding-left: 16px;
   padding-right: 16px;
   height: 32px;
}

.list-links-product-related {
   width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: white;
        border-radius: 16px;
        padding: 16px;
        width: 100%;
        height: 100%;

        .textarea-box {
            width: 100%;
            height: 100%;
        }
    }
}