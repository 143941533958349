.checking-OVH {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 24px;

    .title_main {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 114.286% */
        margin-bottom: 12px;
        letter-spacing: -0.56px;
    }

    &__toolbar-top{
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        gap: 12px;

        .filters-container {
            width: auto;
        }
    }

    &__toolbar-top_right {
            display: flex;
            gap: 12px;
        }

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        flex-wrap: wrap;
    }
}