.landing-products {
    &__img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;

        img {
            display: inline-block;
            max-height: 210px;
        }
    }
}