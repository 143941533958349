.add-competitors {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    width: 100%;
    max-width: 100%;

    &__title-main {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    &__toolbar-top {
        display: flex;
        width: 100%;
        gap: 24px;
        align-items: center;
        justify-content: space-between;
    }

    &__toolbar-medium {
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;
        justify-content: space-between;
    }

    &__btn-load {
        display: flex;
        height: 40px;
        width: 190px;
        padding: 4px 12px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #FFF;
        border-radius: 8px;
        border: 1px solid #EDEFED;
    }

    &__table-panel {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__btn-new-field {
        display: flex;
        height: 33px;
        padding: 20px 40px 20px 12px;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border-radius: 4px;
        border: 1px #DBDBDB solid;
        background: #FFF;
        border-radius: 4px;
        overflow: hidden;
        color: #00B45E;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
    }
}