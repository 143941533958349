.modal-choice-of-strategy {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 760px;
    width: 100%;
    min-width: 360px;
    padding: 32px 0px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 12px 32px 0px rgba(0, 40, 77, 0.12);
    max-height: calc(100vh - 120px - 48px);

    .title {
        color: #020617;
        width: calc(100% - 48px);
        margin-left: 24px;
        margin-right: 24px;
        text-align: center;
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid #E0E7F2;
    }

    &__content {
        display: flex;
        width: calc(100% - 48px);
        padding: 24px;
        width: fit-content;
        margin-bottom: 24px;
        align-items: center;
        flex-wrap: wrap;
        gap: 32px;
        overflow: auto;

        .strategy-item,
        .strategy-item_active {
            display: flex;
            min-width: 216px;
            max-width: 216px;
            height: 200px;
            padding: var(--space-x8, 32px);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            border-radius: 16px;
            background: #FFF;
            border: 2px solid #ffffff;
            box-shadow: 0px 15px 20px 0px rgba(30, 41, 59, 0.06);
            cursor: pointer;

            img {
                width: 32px;
                height: 32px;
            }

            .text {
                text-align: center;
                color: var(--all-text-primary-text, #020617);
                text-align: center;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            &:hover,
            &_active {
                border: 2px solid #1890FF;
            }
        }
    }

    .btn__blue {
        max-width:  calc(560px - 48px);
        width: calc(100% - 48px);
        margin-left: 24px;
        margin-right: 24px;
        min-height: 56px;
    }
}