.drop-information, .drop-information_open {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: #F7FAFD;
    width: 100%;
    position: relative;
    height: 100%;
    max-height: 58px;
    overflow: hidden;
    transition: 1s;

    &:nth-child(2) {
        background-color: #F8F7FD;
    }

    &_open {
        transition: 1s;
        max-height: 200px;
    }

    .header-content {
        display: flex;
        align-items: center;
        min-height: 24px;
        width: 100%;
        cursor: pointer;
    }

    .btn-drop, .btn-drop_active {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 24px;
        height: 24px;
        background-image: url('./img/drop_icon.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-color: inherit;
        border: none;

        &_active {

        }
    }

    .title_min {
        color: #2F506D;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
    }

    .text {
        color: #6B8CA9;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 133.333% */
        letter-spacing: 0.06px;
    }
}