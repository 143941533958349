.products-in-campaign  {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        height: 100%;
    
        .title_main {
            color: #020617;
            font-family: Rubik;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            margin-bottom: 12px;
        }
    
        .filter-group {
            display: flex;
            width: 100%;
            justify-content: space-between;
            gap: 12px;
            flex-wrap: wrap;
        }
    
        .child-table {
            display: flex;
            align-items: center;
            gap: 12px;
    
            .btn-group {
                display: flex;
                gap: 12px;
                width: 100%;
                justify-content: end;
            }
        }
    
        .content-btn {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    
        .metrics-items {
            width: fit-content;
            display: flex;
            width: fit-content;
            gap: 12px;
            flex-wrap: wrap;
        }
    
    }